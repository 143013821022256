import React, { Component } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import updateObject from 'utils/immutable/updateObject';

const propTypes = {
  items: PropTypes.array.isRequired,
  nSettings: PropTypes.object,
  assignCarouselRef: PropTypes.func,
};

class CarouselBaseContainer extends Component {
  render() {
    const { items, nSettings } = this.props;
    let settings = {
      arrows: true,
      dots: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    if (nSettings) {
      settings = updateObject(settings, nSettings);
    }

    if (this.props.assignCarouselRef) {
      return (
        <Slider {...settings} ref={(c) => this.props.assignCarouselRef(c)} asNavFor={this.props.asNavFor}>
          {items.map((item, index) => (
            <div key={`slider-item-${index}`}>{item}</div>
          ))}
        </Slider>
      );
    }

    return (
      <Slider {...settings}>
        {items.map((item, index) => (
          <div key={`slider-item-${index}`}>{item}</div>
        ))}
      </Slider>
    );
  }
}

CarouselBaseContainer.propTypes = propTypes;

export default CarouselBaseContainer;

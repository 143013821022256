import React from 'react';
import { FormattedMessage } from 'i18n';
import ReviewsCount from 'components/ratings/ReviewsCount';

function ReviewToolTip({ reviewsCount, widthAbove900 }) {
  if (widthAbove900) {
    return (
      <FormattedMessage
        id={'packageScore.reviewsCount.tooltip'}
        defaultMessage={
          'Rating from 1-10, based on reviews collected from popular sites such as Booking.com, Hotels.com, Expedia etc.'
        }
        description={
          'Rating from 1-10, based on reviews collected from popular sites such as Booking.com, Hotels.com, Expedia etc.'
        }
      />
    );
  }

  return (
    <div>
      <ReviewsCount
        reviewsCount={reviewsCount}
        style={{ display: 'block', maxWidth: 'unset', padding: '0', textAlign: 'center', color: 'black' }}
      />
      <FormattedMessage
        id={'packageScore.reviewsCount.tooltip'}
        defaultMessage={
          'Rating from 1-10, based on reviews collected from popular sites such as Booking.com, Hotels.com, Expedia etc.'
        }
        description={
          'Rating from 1-10, based on reviews collected from popular sites such as Booking.com, Hotels.com, Expedia etc.'
        }
      />
    </div>
  );
}

export default ReviewToolTip;

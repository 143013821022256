import { isMobile as getIsMobile } from 'utils/isMobile';

export default function getImageSource(source, { small, imgConfig }) {
  let width = imgConfig ? imgConfig.width : 312;
  let height = imgConfig ? imgConfig.height : 212;

  const isMobile = getIsMobile();

  if (small || isMobile) {
    width = 304;
    height = 208;
  }

  return `${source}?w=${width}&h=${height}&dpr=${window.devicePixelRatio}&fit=crop&auto=format`;
}

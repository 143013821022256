import HttpClient from 'httpClient';

export function getAdverts(origin = '', isHolidayPirates) {
  if (isHolidayPirates) {
    const currentDate = new Date().toJSON().slice(0, 13);
    return HttpClient.get(`/wp-json/tripx/v1/highlights/${origin}?rnd=${currentDate}`);
  }

  return HttpClient.get(`/wp-json/tripx/v1/highlights/${origin}`);
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CarouselBaseContainer from 'components/carousel/CarouselBaseContainer';
import { mediaQueries } from 'styles/utils/mediaQueries';

const propTypes = {
  items: PropTypes.array.isRequired,
  assignCarouselRef: PropTypes.func,
};

const CarouselPackagesAdverts = ({ items, assignCarouselRef }) => (
  <CarouselPackagesAdvertsStyled>
    <CarouselBaseContainer
      items={items}
      nSettings={{
        dots: false,
        arrows: false,
        autoplay: false,
        initialSlide: 1,
        slidesToShow: 1,
        centerPadding: '0',
        variableWidth: true,
        swipeToSlide: true,
        rows: 1,
        speed: 1000,
        autoplaySpeed: 5000,
        slidesPerRow: 1,
        pauseOnFocus: true,
        className: 'carousel-packages-adverts',
      }}
      assignCarouselRef={assignCarouselRef}
    />
  </CarouselPackagesAdvertsStyled>
);

CarouselPackagesAdverts.propTypes = propTypes;

export default CarouselPackagesAdverts;

// Custom Styling
const CarouselPackagesAdvertsStyled = styled.div`
  .carousel-packages-adverts {
    ${mediaQueries.fromAndAbove.tablet`
      height: 208px;
    `}

    ${mediaQueries.fromAndAbove.desktop`
      height: unset;
    `}

    ${mediaQueries.fromAndBelow.tablet`
      height: 381px;
    `}
  }

  .slick-list:first-child {
    overflow: visible;

    .slick-track:first-child {
      .slick-slide {
        width: 765px;
        display: flex;
        justify-content: center;
        align-items: center;

        ${mediaQueries.hotelCard`
          width: 325px;
        `}
      }
    }
  }
`;

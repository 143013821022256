import React from 'react';
import styled from 'styled-components';
import Measure from 'react-measure';
import CardImages from './components/cardImages/CardImagesComponent';
import PrimaryContent from './components/primaryContent/PrimaryContentComponent';
import SecondaryContent from './components/secondaryContent/SecondaryContentComponent';
import { mediaQueries } from 'styles/utils/mediaQueries';
import Drawer from './components/drawer/Drawer';
import useFlexWrapActivated from 'hooks/useFlexWrapActivated';

function PackageCardComponent({
  cardId,
  maxWidth,
  marginBottom,
  small,
  imgConfig,
  saved,
  specialOffer,
  priceBanner,
  subheader,
  carouselImages,
  hotelName,
  hotelCity,
  hotelStars,
  hotelDescription,
  hotelPrice,
  hotelScore,
  travelSafe,
  facilities,
  themeIds,
  distance,
  reviewsCount,
  packageDeparture,
  isDirectFlight,
  packageDates,
  packageBoard,
  packageRoomType,
  showDrawer,
  airlineWhitelabel,
  co2Compensation,
  onMouseEnter,
  onMouseLeave,
  onClickAddHearted,
  onClickRemoveHearted,
  isSearchPage,
  isMobile,
  isCenteredLayoutSearchPage,
}) {
  const { isFlexWrapActivated, elementRef } = useFlexWrapActivated({
    skip: !isSearchPage || isMobile || !isCenteredLayoutSearchPage,
  });

  const hasRewardPoints = airlineWhitelabel && (airlineWhitelabel.isNorwegian || airlineWhitelabel.isAirBaltic);
  const hasDrawer = showDrawer && (hasRewardPoints || travelSafe);

  return (
    <Measure client>
      {({ measureRef, contentRect }) => {
        const widthAbove900 = contentRect.client && contentRect.client.width && contentRect.client.width > 900;

        return (
          <Root
            ref={measureRef}
            id={cardId}
            hasDrawer={hasDrawer}
            maxWidth={maxWidth}
            marginBottom={marginBottom}
            small={small}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            data-test='package-card-component'
          >
            <ContentWrap
              small={small}
              ref={(ref) => (elementRef.current = ref)}
              isCenteredLayoutSearchPage={isCenteredLayoutSearchPage}
            >
              <CardImages
                small={small}
                hearted={saved}
                specialOffer={specialOffer}
                carouselImages={carouselImages}
                onClickAddHearted={onClickAddHearted}
                onClickRemoveHearted={onClickRemoveHearted}
                imgConfig={imgConfig}
              />
              <ContentInfo isCenteredLayoutSearchPage={isCenteredLayoutSearchPage} small={small}>
                <PrimaryContent
                  isCard={true}
                  small={small}
                  hotelName={hotelName}
                  hotelCity={hotelCity}
                  hotelStars={hotelStars}
                  hotelDescription={hotelDescription}
                  hotelPrice={hotelPrice}
                  hotelScore={hotelScore}
                  reviewsCount={reviewsCount}
                  packageDeparture={packageDeparture}
                  priceBanner={priceBanner}
                  widthAbove900={widthAbove900}
                  subheader={subheader}
                  airlineWhitelabel={airlineWhitelabel}
                  travelSafe={travelSafe}
                  facilities={facilities}
                  themeIds={themeIds}
                  distance={distance}
                  isFlexWrapActivated={isFlexWrapActivated}
                  isSearchPage={isSearchPage}
                />
                <SecondaryContent
                  small={small}
                  packageDates={packageDates}
                  packageBoard={packageBoard}
                  packageRoomType={packageRoomType}
                  packageDeparture={packageDeparture}
                  co2Compensation={co2Compensation}
                  hasDrawer={hasDrawer}
                  isDirectFlight={isDirectFlight}
                  isFlexWrapActivated={isFlexWrapActivated}
                  isSearchPage={isSearchPage}
                />
              </ContentInfo>
            </ContentWrap>
            {hasDrawer && (
              <Drawer
                small={small}
                airlineWhitelabel={airlineWhitelabel}
                hasRewardPoints={hasRewardPoints}
                travelSafe={travelSafe}
              />
            )}
          </Root>
        );
      }}
    </Measure>
  );
}

export default PackageCardComponent;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${({ theme }) => theme.colors.background.light};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};

  cursor: pointer;
  box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.1);
  transform: translate3d(0, 0, 0);
  /* transition: all 300ms ease-in-out; */
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  ${({ marginBottom }) =>
    marginBottom &&
    `
    margin: 0 auto ${marginBottom};
  `}

  &:hover {
    transform: translate3d(0, -1px, 0);
    box-shadow: 0px 0px 30px -6px rgba(0, 0, 0, 0.2);
  }

  ${({ small }) =>
    small &&
    `
    flex-direction: column;
    width: 304px;
    min-width: 304px;
    height: auto;
  `}

  ${mediaQueries.hotelCard`
    flex-direction: column;
    width: 304px;
    min-width: 304px;
    height: auto;
  `}
`;

const ContentWrap = styled.div`
  display: flex;
  flex-wrap: ${({ isCenteredLayoutSearchPage }) => (isCenteredLayoutSearchPage ? 'wrap' : 'nowrap')};

  ${({ small }) =>
    small &&
    `
    flex-direction: column;
    flex-wrap: nowrap;
  `}

  ${mediaQueries.hotelCard`
    flex-direction: column;
    flex-wrap: nowrap;
  `}
`;

const ContentInfo = styled('div')`
  display: flex;
  flex-wrap: ${({ isCenteredLayoutSearchPage }) => (isCenteredLayoutSearchPage ? 'wrap' : 'nowrap')};
  flex: 1;

  ${({ small }) =>
    small &&
    `
    flex-wrap: nowrap;
    flex-direction: column;
  `}

  ${mediaQueries.hotelCard`
    flex-wrap: nowrap;
    flex-direction: column;
  `}
`;

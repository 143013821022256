import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { mediaQueries } from 'styles/utils/mediaQueries';

const CarouselImage = ({ src, small }) => <ImageHolderStyled src={src} alt='' small={small} loading='lazy' />;

CarouselImage.propTypes = {
  src: PropTypes.string.isRequired,
};

export default CarouselImage;

const ImageHolderStyled = styled.img`
  width: 100%;
  //! ALL IMAGES ON EVERY CARD HAVE 208PX IF YOU CHANGE INSIDE getImageSource, YOU NEED TO CHANGE SEE HOW IT IS USED EVERYWHERE
  height: 212px;
  border-radius: 4px;

  ${({ small }) =>
    small &&
    `
    width: 304px;
    height: 208px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  `}

  ${mediaQueries.hotelCard`
    width: 304px;
    height: 208px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  `}
`;

import {
  KITCHENETTE,
  OUTDOOR_POOL,
  SUITABLE_FOR_CHILDREN,
  WLAN,
  CHILDRENS_POOL,
  RESTAURANT,
  DISTANCE_TO_BEACH,
  AIR_CONDITIONING,
  BAR,
  ROOFTOP,
  INDOOR_POOL,
  SPA,
  POOL_BAR,
  HEALTH_AND_FITNESS_CENTRE_ON_SITE,
  BEACH_BAR,
  DISTANCE_TO_CITY_CENTRE,
  WATER_PARK,
  SMOKE_FREE,
  NIGHTCLUB,
} from 'constants/facilities';

import { APARTMENT, LUXURY, BOUTIQUE, FAMILY, ADULTS_ONLY, CITY, ALL_INCLUSIVE, SUN_AND_BATH } from 'constants/themes';

export const themeFacilitiesOrderMap = {
  [APARTMENT]: [
    KITCHENETTE,
    OUTDOOR_POOL,
    SUITABLE_FOR_CHILDREN,
    WLAN,
    CHILDRENS_POOL,
    RESTAURANT,
    DISTANCE_TO_BEACH,
    AIR_CONDITIONING,
  ],
  [LUXURY]: [
    RESTAURANT,
    BAR,
    ROOFTOP,
    INDOOR_POOL,
    SPA,
    OUTDOOR_POOL,
    POOL_BAR,
    HEALTH_AND_FITNESS_CENTRE_ON_SITE,
    BEACH_BAR,
    WLAN,
  ],
  [BOUTIQUE]: [
    RESTAURANT,
    BAR,
    ROOFTOP,
    OUTDOOR_POOL,
    INDOOR_POOL,
    DISTANCE_TO_CITY_CENTRE,
    SPA,
    HEALTH_AND_FITNESS_CENTRE_ON_SITE,
    WLAN,
  ],
  [FAMILY]: [
    SUITABLE_FOR_CHILDREN,
    OUTDOOR_POOL,
    CHILDRENS_POOL,
    ROOFTOP,
    WATER_PARK,
    RESTAURANT,
    POOL_BAR,
    WLAN,
    AIR_CONDITIONING,
  ],
  [ADULTS_ONLY]: [
    OUTDOOR_POOL,
    RESTAURANT,
    POOL_BAR,
    ROOFTOP,
    SPA,
    NIGHTCLUB,
    DISTANCE_TO_CITY_CENTRE,
    HEALTH_AND_FITNESS_CENTRE_ON_SITE,
    WLAN,
  ],
  [CITY]: [
    RESTAURANT,
    BAR,
    ROOFTOP,
    NIGHTCLUB,
    DISTANCE_TO_CITY_CENTRE,
    INDOOR_POOL,
    HEALTH_AND_FITNESS_CENTRE_ON_SITE,
    SPA,
    OUTDOOR_POOL,
    WLAN,
  ],
  [ALL_INCLUSIVE]: [
    RESTAURANT,
    OUTDOOR_POOL,
    POOL_BAR,
    SUITABLE_FOR_CHILDREN,
    CHILDRENS_POOL,
    WLAN,
    WATER_PARK,
    AIR_CONDITIONING,
  ],
  [SUN_AND_BATH]: [
    DISTANCE_TO_BEACH,
    OUTDOOR_POOL,
    RESTAURANT,
    SUITABLE_FOR_CHILDREN,
    POOL_BAR,
    BEACH_BAR,
    WATER_PARK,
    WLAN,
    AIR_CONDITIONING,
  ],
};

import React, { Component } from 'react';
import FlexibleComponent from './FlexibleComponent';
import setCardFlexibleDates from 'state/selectDates/operations/setCardFlexibleDates';
import { connect } from 'react-redux';

class Flexible extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flexible: null,
    };
    this.setFlexible = this.setFlexible.bind(this);
  }
  componentDidMount() {
    this.setFlexible();
  }

  setFlexible() {
    const { setCardFlexibleDates, dateFrom, dateTo } = this.props;
    const isFlexible = setCardFlexibleDates(dateFrom, dateTo);
    this.setState({
      flexible: isFlexible,
    });
  }

  render() {
    const { flexible } = this.state;

    if (!flexible) {
      return null;
    }

    return <FlexibleComponent />;
  }
}
function mapDispatchToProps(dispatch) {
  return {
    setCardFlexibleDates(dateFrom, dateTo) {
      return dispatch(setCardFlexibleDates(dateFrom, dateTo));
    },
  };
}

export default connect(null, mapDispatchToProps)(Flexible);

import _sortBy from 'lodash/sortBy';
import { getAdverts } from 'httpClient/requests/adverts';
import * as fetchingStatus from 'constants/status/fetchingStatus';
import setFiltersOptions from 'state/advertsFilters/operations/setFiltersOptions';
import { setFetching, setAdverts } from '../actions';

export default function fetchAdverts(origin) {
  return async (dispatch, getState) => {
    const { fetching } = getState().adverts;

    const { customSettings } = getState().settings.value;
    const isHolidayPirates = customSettings && customSettings.isHolidayPirates;

    if (fetching === fetchingStatus.IN_PROGRESS) {
      return;
    }

    dispatch(setFetching(fetchingStatus.IN_PROGRESS));

    try {
      const { data } = await getAdverts(origin, isHolidayPirates);

      dispatch(setAdverts(_sortBy(data, 'dates.from')));
      dispatch(setFiltersOptions());
      dispatch(setFetching(fetchingStatus.SUCCEEDED));
    } catch (error) {
      console.error(error);
      dispatch(setFetching(fetchingStatus.FAILED));
    }
  };
}

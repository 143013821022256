import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeroComponent from './HeroComponent';

class Hero extends Component {
  render() {
    const { airlineWhitelabel, frontBackground, clearHeroBackgroundTint } = this.props;

    return (
      <HeroComponent
        airlineWhitelabel={airlineWhitelabel}
        frontBackground={frontBackground}
        clearHeroBackgroundTint={clearHeroBackgroundTint}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    frontBackground: state.settings.value.style.frontBgUrl,
    clearHeroBackgroundTint:
      state.settings.value.customSettings && state.settings.value.customSettings.clearHeroBackgroundTint,
  };
}

export default connect(mapStateToProps)(Hero);

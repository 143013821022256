import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'i18n';
import { mediaQueries } from 'styles/utils/mediaQueries';

export default function Drawer({ small, airlineWhitelabel, travelSafe, hasRewardPoints }) {
  return (
    <Root>
      {travelSafe && (
        <TravelSafeNotice>
          <FontAwesomeIcon icon='shield-virus' />
          &nbsp;&nbsp;
          <FormattedMessage
            id={'packageCard.travelSafe.label'}
            description={'Travel Safe'}
            defaultMessage={'Travel Safe'}
          />
          <TravelSafeExtended>
            <FormattedMessage
              id={'packageCard.travelSafeExtended.label'}
              description={'Travel Safe'}
              defaultMessage={'Travel Safe'}
            />
          </TravelSafeExtended>
        </TravelSafeNotice>
      )}
      {hasRewardPoints && (
        <CashPoints small={small}>
          <FontAwesomeIcon icon='check' />
          &nbsp;&nbsp;
          <FormattedMessage
            id={`packageCard.rewardPoints.${airlineWhitelabel.brand}.label`}
            description={'Earn 2% CashPoints'}
            defaultMessage={'Earn 2% CashPoints'}
          />
        </CashPoints>
      )}
    </Root>
  );
}

const CashPoints = styled.div`
  font-size: 1.4em;
  color: ${({ theme }) => theme.colors.adjacent.default};

  ${({ small }) =>
    small &&
    `
    width: 100%;
    margin-top: 4px;
  `}
`;

const TravelSafeNotice = styled.div`
  font-size: 1.4em;
  font-weight: bold;
  white-space: nowrap;
  display: flex;
  color: ${({ theme }) => theme.colors.severity.info[0]};
`;

const TravelSafeExtended = styled.label`
  margin-left: 8px;
  font-weight: 500;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary.light};
  border-top: ${({ theme }) => `1px solid ${theme.colors.primary.default}`};

  > *:not(:last-child) {
    margin-right: 16px;
  }

  ${({ small }) =>
    small &&
    `
    justify-content: space-around;
  `}

  ${mediaQueries.hotelCard`
    flex-wrap: wrap;
    justify-content: space-around;
    height: 45px;
  `}
`;

import { defaultOrigin } from 'routes/frontpage/components/adverts/constants';

export default function getAvailableOrigins(origins, adverts, data) {
  const originsCodes = origins.map(getOriginCode);
  const uniqueOrigins = adverts
    .map(getAdvertOriginCode)
    .filter((origin, index, array) => array.indexOf(origin) === index && originsCodes.indexOf(origin) !== -1);

  const formattedOrigins = origins
    .filter((origin) => uniqueOrigins.indexOf(origin.code) !== -1)
    .map((origin) => ({
      value: origin.code,
      label: origin.name,
    }));

  if (data && data.includeDefault) {
    formattedOrigins.unshift(defaultOrigin);
  }

  return formattedOrigins;
}

function getOriginCode(origin) {
  return origin.code;
}

function getAdvertOriginCode(advert) {
  return advert.airport.code;
}

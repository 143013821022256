import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SUCCEEDED as FETCHING_STATUS_SUCCEEDED } from 'constants/status/fetchingStatus';
import getAvailableOrigins from 'state/adverts/selectors/getAvailableOrigins';
import getRandomAdverts from 'state/adverts/selectors/getRandomAdverts';
import filterAdvertsByOrigin from 'state/adverts/selectors/filterAdvertsByOrigin';
import fetchAdverts from 'state/adverts/operations/fetchAdverts';
import executeTrackingEvent from 'managers/gtmEventTracking/executeTrackingEvent';
import { CLICKED_ADVERTS } from 'managers/gtmEventTracking/constants/eventsNames';
import { fetchThemes } from 'state/templates/themes/operations';
import { savedPackagesOperations } from 'state/savedPackages';

import { createPackageCards } from 'components/packageCard/PackageCardFactory';
import { ADVERTS_PACKAGE } from 'components/packageCard/constants/packageTypes';
import AdvertsComponent from './AdvertsComponent';
import { defaultOrigin, defaultAdvertTitle } from './constants';
import updateObject from 'utils/immutable/updateObject';
import _orderBy from 'lodash/orderBy';

class AdvertsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableOrigins: [],
      selectedOrigin: defaultOrigin,
      advertsCardsComponents: {},
    };

    this.handleOnSelectedOriginChange = this.handleOnSelectedOriginChange.bind(this);
    this.handleOnClickPrevPackage = this.handleOnClickPrevPackage.bind(this);
    this.handleOnClickNextPackage = this.handleOnClickNextPackage.bind(this);
    this.assignCarouselRef = this.assignCarouselRef.bind(this);
    this.handleOnClickTrackEvent = this.handleOnClickTrackEvent.bind(this);
  }

  componentDidMount() {
    if (this.props.themes.length === 0) {
      this.props.fetchThemes();
    }
    if (this.props.adverts.length === 0) {
      this.props.fetchAdverts();
    } else {
      this.handleOnSelectedOriginChange(this.props.selectedOrigin);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.adverts.length > 0) {
      if (this.state.availableOrigins.length === 0 && this.props.origins.length > 0) {
        this.setState(
          {
            availableOrigins: getAvailableOrigins(this.props.origins, this.props.adverts, { includeDefault: true }),
          },
          () => this.handleOnSelectedOriginChange(this.props.selectedOrigin)
        );
      }

      const prevSelectedOrigin = prevProps.selectedOrigin && prevProps.selectedOrigin.value;
      if (this.props.selectedOrigin && this.props.selectedOrigin.value !== prevSelectedOrigin) {
        this.handleOnSelectedOriginChange(this.props.selectedOrigin);
      }
    }

    if (this.props.themes.length === 0) {
      this.props.fetchThemes();
    }
  }

  handleOnSelectedOriginChange(selectedOrigin = defaultOrigin, reactSelect, adverts = this.props.adverts) {
    const availableOriginsValues = this.state.availableOrigins.map(({ value }) => value);
    const selectedOriginsIsAvailable = availableOriginsValues.indexOf(selectedOrigin.value) !== -1;

    if (selectedOrigin.value === 'na' || !selectedOriginsIsAvailable) {
      selectedOrigin = defaultOrigin;
    }

    if (this.state.selectedOrigin.value !== selectedOrigin.value) {
      this.setState({ selectedOrigin });
    }

    if (this.state.advertsCardsComponents[selectedOrigin.value] === undefined) {
      const advertsForOrigin = filterAdvertsByOrigin(adverts, selectedOrigin.value);
      const randomAdvertsForOrigin = getRandomAdverts(advertsForOrigin);

      this.setAdvertsForOrigin(randomAdvertsForOrigin, selectedOrigin.value);
    }
  }

  setAdvertsForOrigin(adverts, origin) {
    const advertsCardsComponents = {};
    advertsCardsComponents[origin] = createPackageCards({
      packages: this.sortAdvertsByStartDate(adverts),
      packageType: ADVERTS_PACKAGE,
      configuration: {
        maxWidth: '742px',
        target: '_self',
        wrapPackage: false,
        imgConfig: {
          width: '245',
          height: '208',
        },
      },
      events: {
        onClickAddHearted: this.props.addSavedPackages,
        onClickRemoveHearted: this.props.removeSavedPackages,
        onClickTrackEvent: this.handleOnClickTrackEvent,
      },
    });

    this.setState((state) => ({
      advertsCardsComponents: updateObject(state.advertsCardsComponents, advertsCardsComponents),
    }));
  }

  sortAdvertsByStartDate(adverts) {
    return _orderBy(adverts, 'dates.from', 'asc');
  }

  handleOnClickPrevPackage() {
    const cards = this.state.advertsCardsComponents[this.state.selectedOrigin.value];
    if (cards !== undefined && cards.length > 0) {
      this.slider.slickPrev();
    }
  }

  handleOnClickNextPackage() {
    const cards = this.state.advertsCardsComponents[this.state.selectedOrigin.value];
    if (cards !== undefined && cards.length > 0) {
      this.slider.slickNext();
    }
  }

  assignCarouselRef(refValue) {
    this.slider = refValue;
  }

  handleOnClickTrackEvent(eventData) {
    const { executeTrackingEvent } = this.props;
    executeTrackingEvent(CLICKED_ADVERTS, eventData);
  }

  render() {
    const { airlineWhitelabel, colors, adverts, advertsFetching, customAdvertsTitleKey } = this.props;
    const hidePlaceholder = !!this.state.advertsCardsComponents[this.state.selectedOrigin.value];
    const advertTitleKey = customAdvertsTitleKey || defaultAdvertTitle;

    if (advertsFetching === FETCHING_STATUS_SUCCEEDED && adverts.length === 0) {
      return null;
    }

    return (
      <AdvertsComponent
        packagesAdvertsCards={this.state.advertsCardsComponents[this.state.selectedOrigin.value]}
        hidePlaceholder={hidePlaceholder}
        origins={this.state.availableOrigins}
        selectedOrigin={this.state.selectedOrigin}
        onSelectedOriginChange={this.handleOnSelectedOriginChange}
        handleOnClickPrevPackage={this.handleOnClickPrevPackage}
        handleOnClickNextPackage={this.handleOnClickNextPackage}
        assignCarouselRef={this.assignCarouselRef}
        airlineWhitelabel={airlineWhitelabel}
        colors={colors}
        advertTitleKey={advertTitleKey}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    adverts: state.adverts.adverts,
    advertsFetching: state.adverts.fetching,
    origins: state.selectOrigins.origins,
    selectedOrigin: state.selectOrigins.selectedOrigins[0],
    colors: state.theme.colors,
    themes: state.templates.themes.themes,
    customAdvertsTitleKey: state.settings.value.customSettings && state.settings.value.customSettings.advertsTitleKey,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAdverts() {
      dispatch(fetchAdverts());
    },
    fetchThemes() {
      dispatch(fetchThemes());
    },
    addSavedPackages: function (packet) {
      dispatch(savedPackagesOperations.updateSavedPackages(null, packet));
    },
    removeSavedPackages: function (id) {
      dispatch(savedPackagesOperations.updateSavedPackages(id, null));
    },
    executeTrackingEvent(eventName, eventData) {
      dispatch(executeTrackingEvent(eventName, eventData));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvertsContainer);

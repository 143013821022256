import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import arrowDash from 'static/icons/arrow-dash.svg';
import Flexible from './Flexible/Flexible';

const propTypes = {
  dateFrom: PropTypes.object.isRequired,
  dateTo: PropTypes.object.isRequired,
  numberOfDays: PropTypes.number,
};

function DatesComponent({ dateFrom, dateTo, numberOfDays, isFrontpage, isFlexWrapActivated }) {
  return (
    <Root isFlexWrapActivated={isFlexWrapActivated}>
      <DateStacked date={dateFrom.date()} month={dateFrom.format('MMM')} isFlexWrapActivated={isFlexWrapActivated} />
      <ArrowTo src={arrowDash} alt='to' isFlexWrapActivated={isFlexWrapActivated} />
      <DateStacked date={dateTo.date()} month={dateTo.format('MMM')} isFlexWrapActivated={isFlexWrapActivated} />
      <Divider isFlexWrapActivated={isFlexWrapActivated} />
      <DateStacked
        date={numberOfDays}
        month={
          <FormattedMessage
            id='packageDates.component.days.label'
            description={`{ number, plural, one {day} other {days} }`}
            defaultMessage={`{ number, plural, one {day} other {days} }`}
            values={{
              number: numberOfDays,
            }}
          />
        }
        isFlexWrapActivated={isFlexWrapActivated}
      />
      {!isFrontpage && <Flexible dateFrom={dateFrom} dateTo={dateTo} />}
    </Root>
  );
}

DatesComponent.propTypes = propTypes;

export default DatesComponent;

const Root = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 10px 1rem;
  box-shadow: 0px 1px 1px ${({ theme }) => theme.colors.primary.default};

  ${({ isFlexWrapActivated }) =>
    isFlexWrapActivated &&
    `
    position: absolute;
    top: 6px;
    left: 6px;
    background-color: white;
    padding: 6px;
    width: auto;
    justify-content: flex-start;
    border-radius: 6px;
  `}
`;

const Divider = styled.div`
  position: relative;
  top: 9px;
  width: 1px;
  height: 23px;
  margin: 0 10px;
  background-color: ${({ theme }) => theme.colors.primary.default};

  ${({ isFlexWrapActivated }) =>
    isFlexWrapActivated &&
    `
    top: 0px;
    margin: 0 6px;
  `}
`;

const ArrowTo = styled.img`
  position: relative;
  bottom: 21px;
  height: 10px;
  margin: 0 2px;

  ${({ isFlexWrapActivated }) =>
    isFlexWrapActivated &&
    `
    bottom: 12px;
    height: 8px;
    margin: 0;
  `}
`;

function DateStacked({ date, month, isFlexWrapActivated }) {
  return (
    <DateStackedRoot isFlexWrapActivated={isFlexWrapActivated}>
      <p>{date}</p>
      <p>{month}</p>
    </DateStackedRoot>
  );
}

const DateStackedRoot = styled.div`
  display: inline-block;
  width: 25%;
  text-align: center;

  > *:first-child {
    font-weight: 700;
    font-size: 18px;
    margin: 0 auto;
  }

  > *:last-child {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    margin: 0 auto;
  }

  ${({ isFlexWrapActivated }) =>
    isFlexWrapActivated &&
    `
    width: auto;

    > *:first-child {
    font-weight: 700;
    font-size: 10px;
    margin: 0 auto;
   }

   > *:last-child {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    margin: 0 auto;
    }
  `}
`;

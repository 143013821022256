import React from 'react';
import styled from 'styled-components';
import Tooltip from 'rc-tooltip';
import { FormattedMessage, FormattedCurrency } from 'i18n';
import b from 'i18n/richTextFormats/b';
import BaseStars from 'components/ratings/Stars';
import Score from 'components/ratings/Score';
import ReviewsCount from 'components/ratings/ReviewsCount';
import ReviewTooltip from 'components/packageCard/components/ReviewTooltipComponent';
import flexbox from 'styles/utils/flexbox';
import { mediaQueries } from 'styles/utils/mediaQueries';
import FacilitiesList from 'components/common/accomodation/facilitiesList/FacilitiesList';
import ThemesList from 'components/common/accomodation/themesList/ThemesList';

function PrimaryContentComponent({
  isCard,
  small,
  hotelName,
  hotelCity,
  hotelStars,
  hotelDescription,
  hotelPrice,
  hotelScore,
  reviewsCount,
  packageDeparture,
  priceBanner,
  widthAbove900,
  subheader,
  airlineWhitelabel,
  facilities,
  themeIds,
  distance,
  isFlexWrapActivated,
  isSearchPage,
}) {
  return (
    <Root small={small}>
      <div>
        <HotelName
          small={small}
          airlineWhitelabel={airlineWhitelabel}
          isFlexWrapActivated={isFlexWrapActivated}
          widthAbove900={widthAbove900}
        >
          {hotelName}
        </HotelName>
        {subheader && (
          <Subheader small={small}>
            {hotelCity && (
              <HotelCity small={small} isFlexWrapActivated={isFlexWrapActivated}>
                {hotelCity}
              </HotelCity>
            )}
            {hotelStars && (
              <Tooltip
                placement='top'
                trigger={['hover']}
                overlay={
                  <FormattedMessage
                    id={'message.onhover.stars'}
                    description={'Official rating of hotel'}
                    defaultMessage={'Official rating of hotel'}
                  />
                }
                destroyTooltipOnHide
              >
                <HotelStars small={small} isFlexWrapActivated={isFlexWrapActivated}>
                  <Stars stars={hotelStars} isFlexWrapActivated={isFlexWrapActivated} />
                </HotelStars>
              </Tooltip>
            )}
          </Subheader>
        )}
      </div>
      {hotelDescription && <Description isFlexWrapActivated={isFlexWrapActivated}> {hotelDescription}</Description>}
      <FacilitiesContainer isFlexWrapActivated={isFlexWrapActivated}>
        <FacilitiesList
          themeIds={themeIds}
          facilities={facilities}
          airlineWhitelabel={airlineWhitelabel}
          distance={distance}
          isCard={isCard}
          isFlexWrapActivated={isFlexWrapActivated}
        />
      </FacilitiesContainer>
      <HotelPriceAndThemesWrap
        small={small}
        isFlexWrapActivated={isFlexWrapActivated}
        isMoreThemes={themeIds.length > 1}
      >
        <HotelPrice
          small={small}
          airlineWhitelabel={airlineWhitelabel}
          isFlexWrapActivated={isFlexWrapActivated}
          isSearchPage={isSearchPage}
        >
          <div className='text'>
            {packageDeparture ? (
              <FormattedMessage
                id='hotelcard.component.price.label'
                description='Per person, including flight'
                defaultMessage='Per person,&nbsp;&nbsp; <b>inklusive flygning</b>'
                values={{
                  b,
                }}
              />
            ) : (
              <FormattedMessage
                id='hotelcard.component.priceHotelOnly.label'
                description='Per person, only hotel'
                defaultMessage='Per person, <b>only hotel</b>'
                values={{ b }}
              />
            )}
          </div>
          <div className='price'>
            {priceBanner ? (
              <PriceBanner>
                <FormattedMessage id='hotelcard.component.price.from' description='From' defaultMessage='From' />
              </PriceBanner>
            ) : null}
            <FormattedCurrency value={hotelPrice} />
          </div>
        </HotelPrice>
        <ThemesList themesIds={themeIds} isCard={isCard} isFlexWrapActivated={isFlexWrapActivated} />
      </HotelPriceAndThemesWrap>
      {hotelScore ? (
        <Tooltip
          placement='top'
          trigger={['hover']}
          overlay={<ReviewTooltip reviewsCount={reviewsCount} widthAbove900={widthAbove900} />}
          destroyTooltipOnHide
        >
          <HotelScore small={small} isFlexWrapActivated={isFlexWrapActivated}>
            {widthAbove900 && reviewsCount && <ReviewsCount reviewsCount={reviewsCount} hide={small} />}
            <Score score={hotelScore} airlineWhitelabel={airlineWhitelabel} />
          </HotelScore>
        </Tooltip>
      ) : null}
    </Root>
  );
}

export default PrimaryContentComponent;

const Root = styled.div`
  ${flexbox({
    flexDirection: 'column',
    justifyContent: 'space-between',
  })}

  flex: 1 1 59%;
  position: relative;
  padding: 12px 16px;

  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary.default};

  ${({ small }) =>
    small &&
    `
    width: 100%;
    min-height: 90px;
    border-width: 0 1px 0px 1px;
  `}

  ${mediaQueries.hotelCard`
    width: 100%;
    min-height: 90px;
    border-width: 0 1px 0px 1px;
  `}
`;

const HotelName = styled.h2`
  margin-right: ${({ widthAbove900 }) => (widthAbove900 ? 160 : 60)}px;
  min-height: 23px;
  max-height: 40px;
  color: ${({ theme, airlineWhitelabel }) =>
    airlineWhitelabel && airlineWhitelabel.isAirBaltic
      ? theme.colors.text.price
      : theme.colors.packageCard
      ? theme.colors.packageCard.text
      : theme.colors.secondary.default};
  font-size: 2em;
  font-weight: ${({ airlineWhitelabel }) => (airlineWhitelabel && airlineWhitelabel.isAirBaltic ? 500 : 600)};
  overflow: hidden;

  ${mediaQueries.fromAndBelow.desktop`
    max-width: 180px;
  `}

  ${({ isFlexWrapActivated }) =>
    isFlexWrapActivated &&
    `
    margin-right: 60px;
    font-size: 15px;
  `}


  ${({ small }) =>
    small &&
    `
    margin-right: 0;
    font-size: 18px;
    max-width: 100%;
  `}

   

  ${mediaQueries.hotelCard`
    margin-right: 0;
    font-size: 18px;
    max-width: 100%;

  `}
`;

const Subheader = styled.div`
  ${flexbox({
    alignItems: 'center',
  })}

  ${({ small }) =>
    small &&
    `
    margin-top: 0;
    flex-direction: column;
    align-items: initial;
  `}

  ${mediaQueries.hotelCard`
    margin-top: 0;
    flex-direction: column;
    align-items: initial;
  `}
`;

const HotelCity = styled.p`
  margin-top: 0 !important;
  margin-right: 0.5rem;
  font-size: 1.6em;
  font-weight: 500;

  ${({ small }) =>
    small &&
    `
    width: 100%;
    font-size: 14px;
  `}

  ${({ isFlexWrapActivated }) =>
    isFlexWrapActivated &&
    `
    font-size: 14px;
  `}


  ${mediaQueries.hotelCard`
    width: 100%;
    font-size: 14px;
  `}
`;

const HotelStars = styled.div`
  height: 14px;

  ${({ isFlexWrapActivated }) =>
    isFlexWrapActivated &&
    `
    height: 10px;
  `}

  ${({ small }) =>
    small &&
    `
    width: 100%;
    height: 12px;
    margin-top: 5px;
  `}



  ${mediaQueries.hotelCard`
    width: 100%;
    height: 12px;
    margin-top: 5px;
  `}
`;

const Stars = styled(BaseStars)`
  ${({ isFlexWrapActivated }) =>
    isFlexWrapActivated &&
    `
    font-size: 10px;
  `}
`;

const Description = styled.div`
  overflow: hidden;
  display: ${({ isFlexWrapActivated }) => (isFlexWrapActivated ? 'none' : '-webkit-box')};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 5px;
  font-size: 1.4em;
  font-weight: 500;
  line-height: 1.2;
  color: ${({ theme }) => (theme.colors.packageCard ? theme.colors.packageCard.text : theme.colors.text.default)};

  ${({ small }) =>
    small &&
    `
    display: none;
  `}

  ${mediaQueries.hotelCard`
    display: none;
  `}

  ${mediaQueries.fromAndBelow.l`
    display: none;
  `}
`;

const HotelPriceAndThemesWrap = styled.div`
  ${flexbox({
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end',
  })}
  margin-top: 4px;
  ${({ isFlexWrapActivated, isMoreThemes }) =>
    isFlexWrapActivated &&
    `
    height: ${isMoreThemes ? '46px' : 'auto'};
    align-items: ${isMoreThemes ? 'start' : 'center'};
   `}
`;

const HotelPrice = styled.div`
  display: flex;
  width: 100%;
  align-items: end;
  justify-content: end;
  gap: 6px;
  flex-direction: ${({ isSearchPage }) => (isSearchPage ? 'row' : 'column')};

  .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: ${({ theme }) => (theme.colors.packageCard ? theme.colors.packageCard.text : theme.colors.text.light)};
    font-size: 10px;
    font-weight: ${({ airlineWhitelabel }) => (airlineWhitelabel && airlineWhitelabel.isAirBaltic ? 500 : 600)};
    letter-spacing: 0.2px;
    text-transform: uppercase;

    b {
      color: ${({ theme, airlineWhitelabel }) =>
        airlineWhitelabel && airlineWhitelabel.isCyprus ? theme.colors.scores.green[0] : 'inherit'};
      font-weight: 900;
    }
  }

  .price {
    font-weight: ${({ airlineWhitelabel }) => (airlineWhitelabel && airlineWhitelabel.isAirBaltic ? 500 : 700)};
    font-size: 2.8em;
    color: ${({ theme }) => (theme.colors.packageCard ? theme.colors.packageCard.price : theme.colors.text.price)};
    line-height: unset;
    text-align: right;
    span {
      line-height: 17px;
    }
  }

  ${({ isFlexWrapActivated }) =>
    isFlexWrapActivated &&
    `
    flex-direction: column;
    position: absolute;
    top: 78%;
    right: 10px;
    width: auto;
    background-color: white;
    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.2);
    padding: 6px;
    border-radius: 6px;
    align-items: end;

    .text {
      font-size: 8px;
      align-items: end;
    };

    .price {
      font-size: 1.8em;
    }
  `}

  @media(max-width: 1440px) {
    flex-direction: column;
  }

  ${mediaQueries.fromAndBelow.l`
     flex-direction: row;
     align-items: flex-end;
  `}

  ${mediaQueries.fromAndBelow.desktop`
    flex-direction: column;
    align-items: flex-end;
  `}


  ${({ small }) =>
    small &&
    `
    flex-direction: column;
    align-items: flex-end;

    width: 100%;
    .text,
    .price {
      text-align: right;
      font-size: 2.0em;
    }

    .text {
      font-size: 8px;
    }
  `}



  ${mediaQueries.hotelCard`
    flex-direction: column;
    align-items: flex-end;

    width: 100%;
    .text,
    .price {
      text-align: right;
      font-size: 2.4em;
    }

    .text {
      font-size: 8px;
    }
  `}
`;

const PriceBanner = styled.span`
  position: absolute;
  bottom: -3px;
  font-size: 18px;
  transform: translateX(-130%);
`;

const HotelScore = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  ${({ isFlexWrapActivated }) =>
    isFlexWrapActivated &&
    `
    top: -2.5rem ;
    right: 0.5rem ;
  `}

  ${({ small }) =>
    small &&
    `
    top: auto !important;
    right: auto !important;
    bottom: -29px;
    left: 1rem;
  `}


  ${mediaQueries.hotelCard`
    top: auto !important;
    right: auto !important;
    bottom: -29px;
    left: 1rem;
  `}
`;

const FacilitiesContainer = styled.div`
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 32px;
  overflow: hidden;

  ${({ isFlexWrapActivated }) =>
    isFlexWrapActivated &&
    `
    height: auto;
    position: absolute;
    top: 6px;
    right: 10px;
    max-width: 70px;
  `}
`;

import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setFetching(fetching) {
  return {
    type: types.SET_FETCHING,
    payload: {
      fetching,
    },
  };
}

export function setAdverts(adverts) {
  return {
    type: types.SET_ADVERTS,
    payload: {
      adverts,
    },
  };
}

export function setFilteredAdverts(filteredAdverts) {
  return {
    type: types.SET_FILTERED_ADVERTS,
    payload: {
      filteredAdverts,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}

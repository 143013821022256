import moment from 'moment';
import { setDepartureOptions, setMonthOptions, setTravelLengthOptions } from '../actions';

const travelLengthsMap = {
  2: { value: [2, 3], label: { id: 'travelLength.label.2,3' } },
  3: { value: [2, 3], label: { id: 'travelLength.label.2,3' } },
  4: { value: [4, 6], label: { id: 'travelLength.label.4,6' } },
  5: { value: [4, 6], label: { id: 'travelLength.label.4,6' } },
  6: { value: [4, 6], label: { id: 'travelLength.label.4,6' } },
  7: { value: [7, 9], label: { id: 'travelLength.label.7,9' } },
  8: { value: [7, 9], label: { id: 'travelLength.label.7,9' } },
  9: { value: [7, 9], label: { id: 'travelLength.label.7,9' } },
  10: { value: [10, 15], label: { id: 'travelLength.label.10,15' } },
  11: { value: [10, 15], label: { id: 'travelLength.label.10,15' } },
  12: { value: [10, 15], label: { id: 'travelLength.label.10,15' } },
  13: { value: [10, 15], label: { id: 'travelLength.label.10,15' } },
  14: { value: [10, 15], label: { id: 'travelLength.label.10,15' } },
  15: { value: [10, 15], label: { id: 'travelLength.label.10,15' } },
};

export default function setFiltersOptions() {
  return (dispatch, getState) => {
    const { adverts } = getState().adverts;

    if (adverts.length === 0) {
      return;
    }

    const departures = adverts.map(formatAirportCodeAndNameForReactSelect).map(stringify);

    dispatch(setDepartureOptions([...new Set(departures)].map(parse)));

    const months = adverts.map(formatMonthsForReactSelect).map(stringify);

    dispatch(setMonthOptions([...new Set(months)].map(parse)));

    const travelLengthDays = [...new Set(adverts.map((advert) => advert.dates.days))].sort(function (a, b) {
      return a - b;
    });
    const travelLength = travelLengthDays.map(formatTravelLengthsForReactSelect).map(stringify);

    dispatch(setTravelLengthOptions([...new Set(travelLength)].map(parse)));
  };
}

function formatAirportCodeAndNameForReactSelect(advert) {
  return {
    value: advert.airport.code,
    label: advert.airport.name,
  };
}

function formatMonthsForReactSelect(advert) {
  const momentDate = moment(advert.dates.from);
  return {
    value: momentDate.format('MM'),
    label: momentDate.format('MMMM'),
  };
}

function formatTravelLengthsForReactSelect(days) {
  if (travelLengthsMap[days]) {
    return travelLengthsMap[days];
  }

  return { value: days, label: { id: `travelLength.label.${days}` } };
}

function stringify(departure) {
  return JSON.stringify(departure);
}

function parse(departure) {
  return JSON.parse(departure);
}

import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import CarouselHotelImages from 'components/carousel/CarouselHotelImages';
import Heart from 'components/Heart';
import CornerRibbon from 'components/ribbons/cornerRibbon/CornerRibbonComponent';
import { mediaQueries } from 'styles/utils/mediaQueries';
import toKebabCase from 'utils/string/toKebabCase';

function CardImagesComponent({
  small,
  imgConfig,
  hearted,
  specialOffer,
  carouselImages,
  onClickAddHearted,
  onClickRemoveHearted,
}) {
  return (
    <Root small={small} imgConfig={imgConfig}>
      <HeartWrap>
        <Heart onClickRemoveHearted={onClickRemoveHearted} onClickAddHearted={onClickAddHearted} hearted={hearted} />
      </HeartWrap>
      {typeof specialOffer === 'string' && (
        <CornerRibbon>
          <FormattedMessage
            id={`specialOffer.label.${toKebabCase(specialOffer)}`}
            description={`${specialOffer}`}
            defaultMessage={`${specialOffer}`}
            tagName={'span'}
          />
        </CornerRibbon>
      )}
      {typeof specialOffer === 'boolean' && (
        <CornerRibbon>
          <FormattedMessage
            id={`specialOffer.label`}
            description={`${specialOffer}`}
            defaultMessage={`${specialOffer}`}
          />
        </CornerRibbon>
      )}
      {carouselImages && <CarouselHotelImages items={carouselImages} />}
    </Root>
  );
}

export default CardImagesComponent;

const Root = styled.div`
  position: relative;
  max-width: ${({ imgConfig, small }) => (imgConfig && !small ? imgConfig.width : '312')}px;
  width: 100%;
  height: 212px;

  ${({ small }) =>
    small &&
    `
    max-width: 312px;
    width: 100%;
    height:208px;
  `}

  ${mediaQueries.hotelCard`
    width: 100%;
    height:208px;
    max-width: 312px;
  `}
`;

const HeartWrap = styled.div`
  position: absolute;
  top: 16px;
  right: 14px;
  z-index: 1;
  width: 18px;
`;

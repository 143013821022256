export default {
  9: 'life-ring', // Childrens pool
  31: 'dumbbell', // Health & Fitness Centre (On-Site)
  35: 'swimming-pool', // Indoor Pool
  41: 'arrows-alt-v', // Lift / Elevator
  47: 'swimmer', // Outdoor Pool
  57: 'utensils', // Restaurant(s)
  67: 'spa', // Spa
  69: 'child', // Suitable for children
  88: 'wifi', // WLAN
  90: 'parking', // Parking
  92: 'snowflake', // Air Conditioning
  93: 'moon', // Rooftop
  94: 'shield-virus', //Corona Virus
  95: 'glass-martini-alt', // Bar
  96: 'cocktail', // Pool bar
  97: 'glass-citrus', // Beach bar,
  98: 'smoking-ban', // Smoke free,
  99: 'raindrops', // Water park
  100: 'music', // Nightclub
  101: 'oven', // Kitchen
};

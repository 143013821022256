import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from 'react-modal';
import GenericPlaceholder from 'components/spinners/genericPlaceholder/GenericPlaceholderComponent';
import { mediaQueries } from 'styles/utils/mediaQueries';

const propTypes = {
  open: PropTypes.bool.isRequired,
  spinner: PropTypes.bool,
  children: PropTypes.object,
};

const defaultProps = {
  open: false,
  spinner: true,
  children: null,
};

function ModalSpinnerComponent({ open, spinner, children }) {
  return (
    <Modal isOpen={open} closeTimeoutMS={200} className='centered-modal-content' overlayClassName='centered-modal'>
      <Root>{spinner ? <GenericPlaceholder>{children}</GenericPlaceholder> : children}</Root>
    </Modal>
  );
}

ModalSpinnerComponent.propTypes = propTypes;
ModalSpinnerComponent.defaultProps = defaultProps;

export default ModalSpinnerComponent;

const Root = styled.div`
  padding: 0 16px;

  ${mediaQueries.fromAndAbove.desktop`
    padding: 0;
  `}
`;

import { getHotelPageQueryParams } from 'utils/requestQuery/getQueryParams';
import devEnvsQueryLocalStorage from 'managers/localStorage/devEnvsQuery/devEnvsQueryLocalStorage';

export default function getPackageLink(
  hotelSlug,
  { origin, dates, roomConfigurations, packageRoomId, packagePrice, packageBoardCode },
  affiliateTrackingData,
  urlSearchQuery
) {
  const query = getHotelPageQueryParams(
    {
      origin,
      dates,
      roomConfigurations,
      packageRoomId,
      packagePrice: packagePrice !== undefined ? window.btoa(packagePrice) : null,
      packageBoardCode,
    },
    affiliateTrackingData,
    urlSearchQuery
  );
  const devEnvsQuery = devEnvsQueryLocalStorage.get();
  if (devEnvsQuery) {
    //TODO : remove later - old route redirect for hotel page
    return `/c${hotelSlug}?${query}&${devEnvsQuery}`;
  }
  //TODO : remove later - old route redirect for hotel page
  return `/c${hotelSlug}?${query}`;
}

import React, { Component } from 'react';
import moment from 'moment';
import DatesComponent from './DatesComponent';
import { connect } from 'react-redux';

class Dates extends Component {
  constructor(props) {
    super(props);

    this.months = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
  }

  render() {
    const { from, to, days } = this.props.dates;
    const { isFrontpage, isFlexWrapActivated } = this.props;
    const dateFrom = moment(from);
    const dateTo = moment(to);
    const numberOfDays = days ? days : dateTo.diff(dateFrom, 'days') + 1;

    return (
      <DatesComponent
        dateFrom={dateFrom}
        dateTo={dateTo}
        numberOfDays={numberOfDays}
        isFrontpage={isFrontpage}
        isFlexWrapActivated={isFlexWrapActivated}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    isFrontpage: state.currentPageComponent.isFrontpage,
  };
}

export default connect(mapStateToProps)(Dates);

import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';

const PackageCardPlaceholder = () => (
  <div>
    <PlaceholderImage />
    <PlaceholderContent />
    <PlaceholderContentSecondary>
      <DatesContainer>
        <PlaceholderDate />
        <PlaceholderDate />
        <PlaceholderDate isLast />
      </DatesContainer>
      <BoardsContainer>
        <PlaceholderBoard />
        <PlaceholderBoard />
        <PlaceholderBoard isLast />
      </BoardsContainer>
    </PlaceholderContentSecondary>
  </div>
);

export default PackageCardPlaceholder;

// Styled Components
const separatorMargin = '8px';
const borderRadius = '5px';
const containersHeight = '208px';

const PlaceholderImage = styled.div`
  display: inline-block;
  width: calc(30% - ${separatorMargin});
  height: ${containersHeight};
  margin-right: ${separatorMargin};
  background-color: ${(props) => props.theme.colors.background.placeholder};
  border-radius: ${borderRadius};
  vertical-align: middle;

  animation: react-placeholder-pulse 1.5s infinite;

  ${mediaQueries.hotelCard`
    display: block;
    width: 100%;
    max-width: 304px;
    height: ${containersHeight};
    margin-right: 0;
    margin-bottom: ${separatorMargin};
    vertical-align: unset;
  `}
`;

const PlaceholderContent = styled.div`
  display: inline-block;
  width: calc(50% - ${separatorMargin});
  height: ${containersHeight};
  margin-right: ${separatorMargin};
  background-color: ${(props) => props.theme.colors.background.placeholder};
  border-radius: ${borderRadius};
  vertical-align: middle;

  animation: react-placeholder-pulse 1.5s infinite;

  ${mediaQueries.hotelCard`
    display: block;
    width: 100%;
    max-width: 304px;
    height: 90px;
    margin-right: 0;
    margin-bottom: ${separatorMargin};
    vertical-align: unset;
  `}
`;

const PlaceholderContentSecondary = styled.div`
  display: inline-block;
  width: 20%;
  height: ${containersHeight};
  vertical-align: middle;

  ${mediaQueries.hotelCard`
    display: block;
    width: 100%;
    max-width: 304px;
    height: 90px;
    vertical-align: unset;
  `}
`;

const DatesContainer = styled.div`
  ${mediaQueries.hotelCard`
    display: inline-block;
    height: 100px;
    width: 90px;
    margin-right: ${separatorMargin};
    background-color: ${(props) => props.theme.colors.background.placeholder};
    border-radius: ${borderRadius};
    vertical-align: middle;

    animation: react-placeholder-pulse 1.5s infinite;
  `}
`;

const PlaceholderDate = styled.div`
  display: inline-block;
  margin-right: ${separatorMargin};
  width: calc(33% - 6px);
  height: 54px;
  background-color: ${(props) => props.theme.colors.background.placeholder};
  border-radius: ${borderRadius};

  animation: react-placeholder-pulse 1.5s infinite;

  ${({ isLast }) =>
    isLast &&
    `
    margin-right: 0;
    width: 31%;
  `}

  ${mediaQueries.hotelCard`
    display: none;
  `}
`;

const BoardsContainer = styled.div`
  ${mediaQueries.hotelCard`
    display: inline-block;
    width: 198px;
    vertical-align: middle;
  `}
`;

const PlaceholderBoard = styled.div`
  margin-top: ${separatorMargin};
  width: 100%;
  height: 40px;
  background-color: ${(props) => props.theme.colors.background.placeholder};
  border-radius: ${borderRadius};

  animation: react-placeholder-pulse 1.5s infinite;

  ${mediaQueries.hotelCard`
    margin-top: 0;
    margin-bottom: ${separatorMargin};
    height: 27px;

    ${({ isLast }) =>
      isLast &&
      `
      margin-bottom: 0;
    `}
  `}
`;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IN_PROGRESS as FETCHING_STATUS_IN_PROGRESS } from 'constants/status/fetchingStatus';
import ModalSpinner from 'components/spinners/modalSpinner/ModalSpinnerComponent';

class Loading extends Component {
  render() {
    const { templateFetching } = this.props;
    const open = templateFetching === FETCHING_STATUS_IN_PROGRESS;
    return <ModalSpinner open={open} spinner={true} />;
  }
}

function mapStateToProps(state) {
  return {
    templateFetching: state.template.fetching,
  };
}

export default connect(mapStateToProps)(Loading);

import { PRODUCTION as RUNTIME_HOST_ENV_PRODUCTION } from 'constants/env/runtimeHostEnv';

export default function trackEventEmpty() {
  const isStageEnvironment = process.env.RUNTIME_HOST_ENV !== RUNTIME_HOST_ENV_PRODUCTION;

  if (isStageEnvironment) {
    return;
  }

  window.setTimeout(function () {
    window.dataLayer.push({ event: 'pageDataLoaded' });
  }, 0);
}

import styled from 'styled-components';

const CornerRibbonComponent = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;

  span {
    position: absolute;
    top: 19px;
    right: -21px;
    display: block;
    width: 100px;
    background: ${({ theme }) => theme.colors.adjacent.default};
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    transform: rotate(45deg);

    font-size: 13px;
    color: #fff;
    text-align: center;
    line-height: 20px;

    &:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid ${({ theme }) => theme.colors.adjacent.dark};
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid ${({ theme }) => theme.colors.adjacent.dark};
    }

    &:after {
      content: '';
      position: absolute;
      right: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid ${({ theme }) => theme.colors.adjacent.dark};
      border-bottom: 3px solid transparent;
      border-top: 3px solid ${({ theme }) => theme.colors.adjacent.dark};
    }
  }
`;

export default CornerRibbonComponent;

import {
  OUTDOOR_POOL,
  SUITABLE_FOR_CHILDREN,
  WLAN,
  ROOFTOP,
  SPA,
  HEALTH_AND_FITNESS_CENTRE_ON_SITE,
  WATER_PARK,
  NIGHTCLUB,
  POOL_BAR,
  BEACH_BAR,
  DISTANCE_TO_CITY_CENTRE,
  DISTANCE_TO_BEACH,
  CHILDRENS_POOL,
  RESTAURANT,
  BAR,
  KITCHENETTE,
  AIR_CONDITIONING,
  SMOKE_FREE,
} from 'constants/facilities';

export const defaultFacilitiesOrder = [
  WATER_PARK,
  NIGHTCLUB,
  POOL_BAR,
  BEACH_BAR,
  ROOFTOP,
  DISTANCE_TO_CITY_CENTRE,
  DISTANCE_TO_BEACH,
  HEALTH_AND_FITNESS_CENTRE_ON_SITE,
  SPA,
  CHILDRENS_POOL,
  OUTDOOR_POOL,
  RESTAURANT,
  BAR,
  SUITABLE_FOR_CHILDREN,
  KITCHENETTE,
  WLAN,
  AIR_CONDITIONING,
  SMOKE_FREE,
];

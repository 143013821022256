import React from 'react';
import styled from 'styled-components';
import {
  EmailShareButton,
  EmailIcon,
  // FacebookMessengerShareButton,
  FacebookMessengerIcon,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';

const handleOnMessengerClick = (e, shareUrl) => {
  window.location.href = 'fb-messenger://share?link=' + encodeURIComponent(shareUrl);
};

const SocialMediaShareButtons = ({ shareUrl }) => {
  return (
    <Root>
      <EmailShareButton url={shareUrl}>
        <EmailIcon round size={50} />
      </EmailShareButton>
      <FacebookMessengerShareButton onClick={(e) => handleOnMessengerClick(e, shareUrl)}>
        <FacebookMessengerIcon round size={50} />
      </FacebookMessengerShareButton>
      <ViberShareButton url={shareUrl}>
        <ViberIcon round size={50} />
      </ViberShareButton>
      <WhatsappShareButton url={shareUrl}>
        <WhatsappIcon round size={50} />
      </WhatsappShareButton>
      <TelegramShareButton url={shareUrl}>
        <TelegramIcon round size={50} />
      </TelegramShareButton>
    </Root>
  );
};

export default SocialMediaShareButtons;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FacebookMessengerShareButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

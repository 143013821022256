import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';

function FlexibleComponent() {
  return (
    <Root>
      <FormattedMessage
        id={'searchCards.flexibleDays.indicator.label'}
        description={'Flexible'}
        defaultMessage={'Flexible'}
      />
    </Root>
  );
}

export default FlexibleComponent;

const Root = styled.div`
  display: inline;
  position: absolute;
  z-index: '2';
  top: 100%;
  right: 25%;
  transform: translate(0, -50%);
  padding: 2px 4px;
  background: ${({ theme }) => theme.colors.secondary.default};
  font-size: 12px;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.text.white};
`;

import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Margin } from 'styled-components-spacing';
import Container from 'components/utils/Container';
import { mediaQueries } from 'styles/utils/mediaQueries';
import Adverts from './components/adverts/Adverts';
import Hero from './components/hero/Hero';
import Loading from './components/loading/Loading';
import loadableVisibility from 'react-loadable-visibility/loadable-components';
import InfiniteThreeDots from 'components/spinners/InfiniteThreeDots';

const HomeBoxes = loadableVisibility(() => import('./components/homeBoxes/HomeBoxes'), {
  fallback: <InfiniteThreeDots />,
});

const PopularHotels = loadableVisibility(() => import('./components/popularHotels/PopularHotels'), {
  fallback: <InfiniteThreeDots />,
});

const Checkmarks = loadableVisibility(
  () => import('routes/_components/layout/components/footer/components/checkmarks/Checkmarks'),
  {
    fallback: <InfiniteThreeDots />,
  }
);

function setMarginValues(airlineWhitelabel) {
  if (airlineWhitelabel && airlineWhitelabel.isAirBaltic) {
    return '-30px';
  }

  if (airlineWhitelabel && airlineWhitelabel.isNorwegian) {
    return '-38px';
  }

  return 'auto';
}

function FrontpageComponent({ airlineWhitelabel }) {
  return (
    <Fragment>
      <Hero airlineWhitelabel={airlineWhitelabel} />
      <Root marginValue={setMarginValues(airlineWhitelabel)}>
        <Adverts airlineWhitelabel={airlineWhitelabel} />
        <Container>
          <Margin top={{ tiny: 4, desktop: 6 }}>
            <HomeBoxes />
          </Margin>
        </Container>
        <Margin top='5' />
        <PopularHotels />
      </Root>
      <Checkmarks />
      <Loading />
    </Fragment>
  );
}

export default FrontpageComponent;

const Root = styled.div`
  overflow-x: hidden;

  ${mediaQueries.fromAndAbove.desktop`
    margin-top: ${({ marginValue }) => marginValue};
  `}
`;

import shuffle from 'utils/array/shuffle';

export default function getRandomAdverts(adverts) {
  const _adverts = adverts.filter(fullyLoadedAdverts);
  const numberOfAdverts = _adverts.length;

  if (numberOfAdverts <= 25) {
    return _adverts;
  }

  const shuffledAdverts = shuffleAdverts(_adverts.length, 25);
  return _adverts.filter((advert, index) => shuffledAdverts.indexOf(index) !== -1);
}

function shuffleAdverts(advertsNumber, displayNumber) {
  const randomAdvertIndexes = [...Array(advertsNumber).keys()];
  return shuffle(randomAdvertIndexes).slice(0, displayNumber);
}

function fullyLoadedAdverts(advert) {
  return advert.hotel.images;
}

import { useState, useEffect, useRef } from 'react';

/**
 * Custom hook to determine if flex-wrap is activated on a referenced element.
 *
 * @function
 * @returns {Object} - An object containing `isFlexWrapActivated` boolean state and `elementRef` reference.
 *
 * @property {boolean} isFlexWrapActivated - State indicating whether flex-wrap is activated on the referenced element.
 * @property {Object} elementRef - Reference to the element being observed.
 * @property {number} threshold - Threshold for any case if client height has changed.
 *
 *
 * @example
 * const { isFlexWrapActivated, elementRef } = useFlexWrapActivated();
 *
 * <div ref={elementRef}>
 *   <ChildComponent />
 * </div>
 */

function useFlexWrapActivated({ threshold = 6, skip = false }) {
  const [isFlexWrapActivated, setIsFlexWrapActivated] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    if (skip) {
      if (isFlexWrapActivated) {
        setIsFlexWrapActivated(false);
      }

      return;
    }

    const ref = elementRef;

    const checkFlexWrap = () => {
      if (ref.current) {
        const element = ref.current;
        const firstChild = element.firstElementChild;

        const isWrapped = element.clientHeight > firstChild.clientHeight + threshold;

        setIsFlexWrapActivated(isWrapped);
      }
    };

    const resizeObserver = new ResizeObserver(checkFlexWrap);

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [isFlexWrapActivated, skip, threshold]);

  return { isFlexWrapActivated, elementRef };
}

export default useFlexWrapActivated;

import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from 'styles/utils/mediaQueries';

const PackageWrapComponent = ({ children }) => <PackageWrap>{children}</PackageWrap>;

export default PackageWrapComponent;

// Styled components
const PackageWrap = styled.div`
  margin-right: 25px;

  ${mediaQueries.fromAndBelow.tablet`
    margin-right: 0;
  `}
`;

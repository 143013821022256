import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FRONTPAGE as PAGE_COMPONENT_KEY_FRONTPAGE } from 'constants/pagesComponentsKeys';
import { setCurrentPageComponent } from 'state/currentPageComponent/actions';
import { setTransitionToCheckout } from 'state/templates/hotel/actions';
import trackEventEmpty from 'managers/gtmEventTracking/utils/trackEventEmpty';
import FrontpageComponent from 'routes/frontpage/FrontpageComponent';
import executeTrackingEvent from 'managers/gtmEventTracking/executeTrackingEvent';
import { FB_FIND_LOCATION } from 'managers/gtmEventTracking/constants/eventsNames';

class Frontpage extends Component {
  async componentDidMount() {
    const { executeTrackingEvent } = this.props;

    const { setCurrentPageComponent, setTransitionToCheckout } = this.props;
    setCurrentPageComponent(PAGE_COMPONENT_KEY_FRONTPAGE);
    setTransitionToCheckout(false);
    executeTrackingEvent(FB_FIND_LOCATION);

    trackEventEmpty();
  }

  render() {
    const { airlineWhitelabel } = this.props;

    return <FrontpageComponent airlineWhitelabel={airlineWhitelabel} />;
  }
}

function mapStateToProps(state) {
  return {
    airlineWhitelabel: state.settings.value.airlineWhitelabel,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentPageComponent(currentPageComponent) {
      dispatch(setCurrentPageComponent(currentPageComponent));
    },
    setTransitionToCheckout(transitionToCheckout) {
      dispatch(setTransitionToCheckout(transitionToCheckout));
    },
    executeTrackingEvent(eventName, eventData) {
      dispatch(executeTrackingEvent(eventName, eventData));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Frontpage);

import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setDepartureOptions(options) {
  return {
    type: types.SET_DEPARTURE_OPTIONS,
    payload: {
      options,
    },
  };
}

export function setDepartureValue(value) {
  return {
    type: types.SET_DEPARTURE_VALUE,
    payload: {
      value,
    },
  };
}

export function setMonthOptions(options) {
  return {
    type: types.SET_MONTH_OPTIONS,
    payload: {
      options,
    },
  };
}

export function setMonthValue(value) {
  return {
    type: types.SET_MONTH_VALUE,
    payload: {
      value,
    },
  };
}

export function setTravelLengthOptions(options) {
  return {
    type: types.SET_TRAVEL_LENGTH_OPTIONS,
    payload: {
      options,
    },
  };
}

export function setTravelLengthValue(value) {
  return {
    type: types.SET_TRAVEL_LENGTH_VALUE,
    payload: {
      value,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}

import React from 'react';
import styled from 'styled-components';
import Tooltip from 'rc-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'i18n';
import Dates from 'components/packageCard/components/dates/Dates';
import flexbox from 'styles/utils/flexbox';
import { mediaQueries } from 'styles/utils/mediaQueries';
import truncate from 'styles/utils/truncate';

function SecondaryContentComponent({
  small,
  packageDates,
  packageBoard,
  packageRoomType,
  packageDeparture,
  co2Compensation,
  hasDrawer,
  isDirectFlight,
  isFlexWrapActivated,
  isSearchPage,
}) {
  return (
    <Root small={small} hasDrawer={hasDrawer} isFlexWrapActivated={isFlexWrapActivated}>
      {packageDates ? <Dates dates={packageDates} isFlexWrapActivated={isFlexWrapActivated} /> : <NoDatesPlaceholder />}
      <PackageInfo
        small={small}
        co2Compensation={co2Compensation}
        packageDeparture={packageDeparture}
        isFlexWrapActivated={isFlexWrapActivated}
      >
        {packageBoard && (
          <Item
            co2Compensation={co2Compensation}
            isFlexWrapActivated={isFlexWrapActivated}
            packageDeparture={packageDeparture}
          >
            <Icon icon='utensils' />
            <Tooltip placement='top' trigger={['hover']} overlay={<span>{packageBoard}</span>} destroyTooltipOnHide>
              <BoardInfoWrap>
                <BoardInfoSpan isSearchPage={isSearchPage} isFlexWrapActivated={isFlexWrapActivated}>
                  {packageBoard}
                </BoardInfoSpan>
              </BoardInfoWrap>
            </Tooltip>
          </Item>
        )}
        {packageRoomType && (
          <Item
            co2Compensation={co2Compensation}
            isFlexWrapActivated={isFlexWrapActivated}
            packageDeparture={packageDeparture}
          >
            <Icon icon='bed' />
            <Tooltip placement='top' trigger={['hover']} overlay={<span>{packageRoomType}</span>} destroyTooltipOnHide>
              <BoardInfoWrap>
                <BoardInfoSpan isSearchPage={isSearchPage} isFlexWrapActivated={isFlexWrapActivated}>
                  {packageRoomType}
                </BoardInfoSpan>
              </BoardInfoWrap>
            </Tooltip>
          </Item>
        )}
        {packageDeparture ? (
          <Item
            co2Compensation={co2Compensation}
            isFlexWrapActivated={isFlexWrapActivated}
            packageDeparture={packageDeparture}
          >
            <FlightMessageWrap>
              <Icon icon='plane-departure' />
              {isDirectFlight && (
                <DirectFlightMessage>
                  <FormattedMessage id={'package.directFlight'} defaultMessage={'Direct'} />
                </DirectFlightMessage>
              )}
            </FlightMessageWrap>

            <Tooltip placement='top' trigger={['hover']} overlay={<span>{packageDeparture}</span>} destroyTooltipOnHide>
              <BoardInfoWrap isDirectFlight={isDirectFlight}>
                <BoardInfoSpan isSearchPage={isSearchPage} isFlexWrapActivated={isFlexWrapActivated}>
                  {packageDeparture}
                </BoardInfoSpan>
              </BoardInfoWrap>
            </Tooltip>
          </Item>
        ) : (
          <Item
            co2Compensation={co2Compensation}
            isFlexWrapActivated={isFlexWrapActivated}
            packageDeparture={packageDeparture}
          >
            <Icon icon='building' />
            <BoardInfoWrap>
              <BoardInfoSpan isSearchPage={isSearchPage} isFlexWrapActivated={isFlexWrapActivated}>
                <FormattedMessage id={'originsDropdown.hotelOnly.label'} defaultMessage={'Only Hotel'} />
              </BoardInfoSpan>
            </BoardInfoWrap>
          </Item>
        )}
        {co2Compensation && packageDeparture && (
          <Item
            co2Compensation={co2Compensation}
            isFlexWrapActivated={isFlexWrapActivated}
            packageDeparture={packageDeparture}
          >
            <CO2Image
              src={
                'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/apple/232/deciduous-tree_1f333.png'
              }
            />
            <Tooltip
              placement='top'
              trigger={['hover']}
              overlay={
                <FormattedMessage
                  id={'co2compensation.tooltip'}
                  description={'CO2 Compensation for flight included'}
                  defaultMessage={'CO2 Compensation for flight included'}
                />
              }
              destroyTooltipOnHide
            >
              <BoardInfoWrap>
                <BoardInfoSpan isSearchPage={isSearchPage}>
                  <FormattedMessage
                    id={'co2compensation.compensated'}
                    description={'CO2 Compensated'}
                    defaultMessage={'CO2 Compensated'}
                  />
                </BoardInfoSpan>
              </BoardInfoWrap>
            </Tooltip>
          </Item>
        )}
      </PackageInfo>
    </Root>
  );
}

export default SecondaryContentComponent;

const Root = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.primary.light};
  flex-direction: column;
  flex: 1 1 auto;
  width: ${({ isFlexWrapActivated }) => (isFlexWrapActivated ? '100%' : '180px')};

  ${({ isFlexWrapActivated }) =>
    isFlexWrapActivated &&
    `
    max-width: 100%;
    `}

  ${({ small }) =>
    small &&
    `
    display: flex;
    width: 100%;
    min-height: 91px;
    flex-direction: row;
    max-width: 100%;
`}

  ${mediaQueries.hotelCard`
    display: flex;
    width: 100%;
    min-height: 91px;
    flex-direction: row;
    max-width: 100%;
  `};
`;

const NoDatesPlaceholder = styled.div`
  flex-basis: 50%;
`;

const PackageInfo = styled.div`
  ${flexbox({
    flexDirection: 'column',
    alignItems: 'space-around',
  })}
  width: 100%;
`;

const Item = styled.div`
  padding: ${({ co2Compensation, packageDeparture }) => (co2Compensation && packageDeparture ? '9px' : '0px')} 1rem;
  font-size: 13px;
  font-weight: 500;
  border-color: ${({ theme }) => theme.colors.primary.default};
  border-top-width: 1px;
  border-top-style: solid;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.default};
  height: 50px;

  &:first-child {
    border-top: 0;
  }

  ${({ isFlexWrapActivated }) =>
    isFlexWrapActivated &&
    `
    height: auto;
    padding: 4px 20px;
    border: none;
    max-width: 312px;
  `};

  ${({ small }) =>
    small &&
    `
      padding: 6px 6px 6px 1rem;
    `}

  ${mediaQueries.hotelCard`
      padding: 6px 6px 6px 1rem;
      height: 30px;
      aling-items: center;
    `}
`;

const BoardInfoWrap = styled.div`
  display: inline-block;
  width: calc(100% - 25px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin-left: ${({ isDirectFlight }) => (isDirectFlight ? '-8px' : '0px')};
`;

const BoardInfoSpan = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: middle;

  padding: 2px 0px;

  max-width: 196px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ isSearchPage }) =>
    !isSearchPage &&
    `
    ${truncate('94px')};
  `};

  ${({ isFlexWrapActivated }) =>
    isFlexWrapActivated &&
    `
    max-width: 250px;
  `};

  ${mediaQueries.fromAndBelow.l`
    max-width:300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  ${mediaQueries.fromAndBelow.desktop`
    ${truncate('94px')};
  `}

  ${mediaQueries.hotelCard`
    ${truncate('94px')};
  `}
`;

const CO2Image = styled.img`
  display: inline !important;
  width: 1.25em;
  margin-right: 0.5rem;
  vertical-align: middle;
`;

const FlightMessageWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const DirectFlightMessage = styled.div`
  transform: translate(-32%, -11%);
  padding: 2px 4px;
  background: ${({ theme }) => theme.colors.secondary.default};
  font-size: 11px;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.text.white};
`;

const Icon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.colors.text.label && theme.colors.text.label,
  width: '20px',
  marginRight: ' 0.5rem',
}));

import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import InfiniteCircleDots from 'components/spinners/InfiniteCircleDots';
import flexbox from 'styles/utils/flexbox';

function GenericPlaceholderComponent({ useDefaultMessage, children }) {
  return (
    <Root>
      <Content>
        {useDefaultMessage && (
          <DefaultMessage>
            <FormattedMessage id={'pleaseWait.message'} description={'Please wait'} defaultMessage={'Please wait'} />
          </DefaultMessage>
        )}
        {children}
      </Content>
      <Spinner>
        <InfiniteCircleDots removeMargin />
      </Spinner>
    </Root>
  );
}

export default GenericPlaceholderComponent;

const Root = styled.div`
  ${flexbox({
    flexDirection: 'column',
    justifyContent: 'center',
  })}
`;

const DefaultMessage = styled.div`
  font-size: 1.6em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text.default};
`;

const Content = styled.div`
  margin-bottom: 36px;
  font-size: 1.6em;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.default};
  animation: react-placeholder-pulse 1.5s infinite;
`;

const Spinner = styled.div`
  margin: 46px auto;
`;

export const DISTANCE_TO_CITY_CENTRE = 1;
export const DISTANCE_TO_BEACH = 2;
export const CHILDRENS_POOL = 9;
export const HEALTH_AND_FITNESS_CENTRE_ON_SITE = 31;
export const INDOOR_POOL = 35;
export const LIFT_ELEVATOR = 41;
export const OUTDOOR_POOL = 47;
export const RESTAURANT = 57;
export const SPA = 67;
export const SUITABLE_FOR_CHILDREN = 69;
export const WLAN = 88;
export const PARKING = 90;
export const AIR_CONDITIONING = 92;
export const ROOFTOP = 93;
export const HEALTHY_AND_SAFETY_PROTOCOL = 94;
export const BAR = 95;
export const POOL_BAR = 96;
export const BEACH_BAR = 97;
export const SMOKE_FREE = 98;
export const WATER_PARK = 99;
export const NIGHTCLUB = 100;
export const KITCHENETTE = 101;

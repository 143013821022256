import moment from 'moment';

export default function setCardFlexibleDates(hotelDateFrom, hotelDateTo) {
  return (dispatch, getState) => {
    const selectDates = getState().selectDates;

    const dateFrom = selectDates.dates.from;
    const dateTo = selectDates.dates.to;

    if (!dateFrom || !dateTo) {
      return null;
    }
    const _hotelDateFrom = moment(hotelDateFrom).format('YYYY-MM-DD');
    const _dateFrom = moment(dateFrom).format('YYYY-MM-DD');
    const _hotelDateTo = moment(hotelDateTo).format('YYYY-MM-DD');
    const _dateTo = moment(dateTo).format('YYYY-MM-DD');
    if (moment(_hotelDateFrom).isSame(_dateFrom) && moment(_hotelDateTo).isSame(_dateTo)) {
      return null;
    }
    return true;
  };
}

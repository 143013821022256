import React from 'react';
import { FormattedMessage } from 'i18n';
import IconCards from 'components/dataPresentation/iconCards/IconCardsComponent';

const title = <FormattedMessage id={'facilities.label'} description={'Facilities'} defaultMessage={'Facilities'} />;

function FacilitiesListComponent({
  items,
  isHotelPage,
  isSearchResults,
  isCard,
  airlineWhitelabel,
  isFlexWrapActivated,
}) {
  return (
    <IconCards
      title={title}
      items={items}
      isHotelPage={isHotelPage}
      isSearchResults={isSearchResults}
      isCard={isCard}
      airlineWhitelabel={airlineWhitelabel}
      isFlexWrapActivated={isFlexWrapActivated}
    />
  );
}

export default FacilitiesListComponent;

/**

 * @param {object} flightInfo Flight info for package
 * @param {string} packageType Package type 
 * @return {boolean} returns true only if the package Leave and Return flights have no stops 
 */

import { SEARCH_RESULTS_PACKAGE } from '../constants/packageTypes';
export const getIsPackageDirectFlight = (flightInfo, packageType) => {
  if (!flightInfo || packageType !== SEARCH_RESULTS_PACKAGE) {
    return false;
  }

  return flightInfo.leaveStopCount === 0 && flightInfo.returnStopCount === 0;
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'i18n';
import { fetchThemes } from 'state/templates/themes/operations';
import ColoredLinks from 'components/dataPresentation/coloredLinks/ColoredLinksComponents';
import { isMobile as getIsMobile } from 'utils/isMobile';

const title = <FormattedMessage id={'themes.title'} description={'Themes'} defaultMessage={'Themes'} />;

const propTypes = {
  themesIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const defaultProps = {
  themes: [],
  themesIds: [],
};

class ThemesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      themesList: null,
    };
  }

  componentDidMount() {
    const { themes, fetchThemes } = this.props;

    if (themes.length === 0) {
      fetchThemes();
    } else {
      this.setThemesList();
    }
  }

  componentDidUpdate(prevProps) {
    const { themes } = this.props;
    const { themes: prevThemes } = prevProps;
    const themesLoaded = prevThemes.length === 0 && themes.length > 0;

    if (themesLoaded) {
      this.setThemesList();
    }
  }

  setThemesList() {
    const { themes, themesIds } = this.props;

    if (themes.length === 0 || themesIds.length === 0) {
      return;
    }

    this.setState({
      themesList: themes.filter(({ id }) => themesIds.indexOf(id) !== -1),
    });
  }

  render() {
    const { isHotelPage, isSearchResults, isCard, isFlexWrapActivated } = this.props;
    const { themesList } = this.state;

    if (themesList === null) {
      return null;
    }

    const isMobile = getIsMobile();
    const slice = isHotelPage && !isCard ? 6 : !isMobile && isSearchResults ? (isFlexWrapActivated ? 4 : 5) : 2;
    const links = themesList.slice(0, slice);

    return (
      <ColoredLinks title={title} links={links} isHotelPage={isHotelPage} isCard={isCard || isFlexWrapActivated} />
    );
  }
}

function mapStateToProps(state) {
  return {
    themes: state.templates.themes.themes,
    isHotelPage: state.currentPageComponent.isHotelPage,
    isSearchResults: state.currentPageComponent.isSearchResults,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchThemes() {
      dispatch(fetchThemes());
    },
  };
}

ThemesList.propTypes = propTypes;
ThemesList.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ThemesList);

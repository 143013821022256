import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const propTypes = {
  score: PropTypes.number.isRequired,
  style: PropTypes.object,
};

function Score({ score, style, airlineWhitelabel }) {
  return (
    <Root style={style} airlineWhitelabel={airlineWhitelabel}>
      {score}
    </Root>
  );
}

Score.propTypes = propTypes;

export default Score;

const Root = styled.span`
  display: inline-block;
  min-width: 48px;
  vertical-align: middle;
  padding: 6px 8px 5px;
  background-color: ${({ theme }) => theme.colors.scores.green[0]};
  border-radius: 2px;

  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-weight: ${({ airlineWhitelabel }) => (airlineWhitelabel && airlineWhitelabel.isAirBaltic ? 500 : 800)};
  font-size: 2.4em;
  color: ${({ theme }) => theme.colors.scores.text};
  line-height: 1;
  text-align: center;
  user-select: none;
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

const propTypes = {
  frontBackground: PropTypes.string.isRequired,
};

function HeroComponent({ airlineWhitelabel, frontBackground, clearHeroBackgroundTint }) {
  return (
    <Root
      airlineWhitelabel={airlineWhitelabel}
      image={frontBackground}
      clearHeroBackgroundTint={clearHeroBackgroundTint}
    />
  );
}

HeroComponent.propTypes = propTypes;

export default HeroComponent;

const Root = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100vw;
  height: 100vh;

  ${({ clearHeroBackgroundTint, theme, image }) => {
    if (clearHeroBackgroundTint) {
      return css`
        background: url(${image});
      `;
    } else {
      return css`
        background: linear-gradient(
            ${rgba(theme.colors.hero.tint || theme.colors.secondary.default, 0.8)},
            ${rgba(theme.colors.hero.tint || theme.colors.secondary.default, 0.15)} 50%,
            ${rgba(theme.colors.hero.tint || theme.colors.secondary.default, 0)} 100%
          ),
          url(${image});
      `;
    }
  }}

  background-size: cover;
  background-position: center;
  opacity: ${({ airlineWhitelabel }) => (airlineWhitelabel && airlineWhitelabel.isAirMalta ? '0.7' : 'initial')};
  filter: ${({ airlineWhitelabel }) => (airlineWhitelabel && airlineWhitelabel.isAirMalta ? 'blur(3px)' : 'initial')};
`;

import React from 'react';
import { components } from 'react-select';

import { FormattedMessage } from 'i18n';
import { defaultOrigin } from '../constants';

const CustomOption = (props) => {
  if (props.data.value === defaultOrigin.value) {
    return (
      <components.Option {...props}>
        <FormattedMessage
          id='adverts.component.option.allOrigins'
          description='All departure airports'
          defaultMessage='All departure airports'
        />
      </components.Option>
    );
  }

  return <components.Option {...props} />;
};

export default CustomOption;

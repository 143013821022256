import React, { Component } from 'react';
import { connect } from 'react-redux';
import _union from 'lodash/union';
import { FormattedMessage } from 'i18n';
import facilitiesIcons from 'constants/facilitiesIcons';
import convertMetersToKilometers from 'utils/convertMetersToKilometers/convertMetersToKilometers';
import FacilitiesListComponent from './FacilitiesListComponent';
import { defaultFacilitiesOrder } from './constants/defaultFacilitiesOrder';
import { themeIdsOrder } from './constants/themeIdsOrder';
import { themeFacilitiesOrderMap } from './constants/themeFacilitiesOrderMap';

class FacilitiesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedItems: [],
      stopLoop: false,
    };
  }

  sortFacilities(items, isHotelPage, isCard, orderThemeId) {
    const order = orderThemeId
      ? _union(themeFacilitiesOrderMap[orderThemeId], defaultFacilitiesOrder)
      : defaultFacilitiesOrder;

    return items
      .sort((a, b) => {
        if (order.indexOf(a.id) === -1) return 1;
        if (order.indexOf(b.id) === -1) return -1;

        return order.indexOf(a.id) - order.indexOf(b.id);
      })
      .filter((item) => (!isHotelPage || (isHotelPage && isCard) ? order.includes(item.id) : item));
  }

  generateFacilitiesItems(facilities) {
    return facilities
      .map((facility) => ({ ...facility, icon: facilitiesIcons[facility.id] }))
      .filter(({ name }) => name !== '');
  }

  appendDistanceFacilities(distance, sortedItems) {
    if (distance) {
      const { beach, cityCentre } = distance;

      if (cityCentre) {
        sortedItems.unshift({
          id: 1,
          name: (
            <FormattedMessage
              id={'distanceTo.cityCentre.label'}
              description={`City center {value}`}
              defaultMessage={`City center {value}`}
              values={{ value: convertMetersToKilometers(cityCentre) }}
            />
          ),
          icon: 'city',
        });
      }

      if (beach) {
        sortedItems.unshift({
          id: 2,
          name: (
            <FormattedMessage
              id={'distanceTo.beach.label'}
              description={`Beach {value}`}
              defaultMessage={`Beach {value}`}
              values={{ value: convertMetersToKilometers(beach) }}
            />
          ),
          icon: 'umbrella-beach',
        });
      }
    }

    return sortedItems;
  }

  getFirstSortedThemeId(themes) {
    if (themes && themes.length !== 0) {
      return themes.sort((a, b) => {
        if (themeIdsOrder.indexOf(a) === -1) return 1;
        if (themeIdsOrder.indexOf(b) === -1) return -1;

        return themeIdsOrder.indexOf(a) - themeIdsOrder.indexOf(b);
      })[0];
    }
  }

  componentDidMount() {
    const { facilities, isHotelPage, isCard, distance, themeIds } = this.props;
    const orderThemeId = this.getFirstSortedThemeId(themeIds);

    if (facilities && facilities.length > 0) {
      const items = this.generateFacilitiesItems(facilities);
      const _items = this.appendDistanceFacilities(distance, items);
      const sortedItems = this.sortFacilities(_items, isHotelPage, isCard, orderThemeId);

      this.setState({ sortedItems: sortedItems, stopLoop: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { sortedItems, stopLoop } = this.state;

    if (sortedItems.length === 0 && !stopLoop) {
      const { facilities, isHotelPage, isCard, distance, themeIds } = this.props;
      const orderThemeId = this.getFirstSortedThemeId(themeIds);

      if (facilities && facilities.length > 0) {
        const items = this.generateFacilitiesItems(facilities);
        const _items = this.appendDistanceFacilities(distance, items);
        const sortedItems = this.sortFacilities(_items, isHotelPage, isCard, orderThemeId);
        this.setState({ sortedItems: sortedItems, stopLoop: true });
      }
    }
  }

  render() {
    const { isHotelPage, isSearchResults, isCard, airlineWhitelabel, isFlexWrapActivated } = this.props;
    const { sortedItems } = this.state;

    return (
      <FacilitiesListComponent
        items={sortedItems}
        isHotelPage={isHotelPage}
        isSearchResults={isSearchResults}
        isCard={isCard}
        airlineWhitelabel={airlineWhitelabel}
        isFlexWrapActivated={isFlexWrapActivated}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    isHotelPage: state.currentPageComponent.isHotelPage,
    isSearchResults: state.currentPageComponent.isSearchResults,
  };
}

export default connect(mapStateToProps)(FacilitiesList);

import React from 'react';
import styled from 'styled-components';
import PrimaryButton from 'components/buttons/primaryButton/PrimaryButton';
import { mediaQueries } from 'styles/utils/mediaQueries';
import Modal from 'react-modal';
import { darken } from 'polished';
import Input from 'components/inputs/input_v2/Input';
import SocialMediaShareButtons from './SocialMediaShareButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'i18n';

const ShareModal = ({ modalOpen, onClickClose, shareUrl, onClickCopy, isUrlCopied, onAfterClose }) => {
  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={onClickClose}
      onAfterClose={onAfterClose}
      closeTimeoutMS={300}
      className='custom-modal-content'
      overlayClassName='custom-modal'
    >
      <Root>
        <Header>
          <Message>
            <FormattedMessage
              id={'saveForLater.button'}
              description={'Save for later'}
              defaultMessage={'Save for later'}
            />
          </Message>
          <CloseButton onClick={onClickClose}>&times;</CloseButton>
        </Header>
        <Content>
          <ShareIconsWrap>
            <SocialMediaShareButtons shareUrl={shareUrl} />
          </ShareIconsWrap>
          <CopyLinkWrap>
            <Input
              name={'link-input'}
              type={'text'}
              size={23}
              value={shareUrl}
              onChange={function () {}}
              disabled={false}
              styles={{ input: { minWidth: '242px', marginBottom: '8px' } }}
            />
            <Button onClick={onClickCopy} isUrlCopied={isUrlCopied}>
              <CopiedIcon icon={isUrlCopied ? 'check' : 'copy'} />
            </Button>
          </CopyLinkWrap>
        </Content>
      </Root>
    </Modal>
  );
};

export default ShareModal;

const Root = styled.div`
  background: ${({ theme }) => theme.colors.background.light};
  padding: 25px;
  font-size: 16px;

  ${mediaQueries.fromAndAbove.desktop`
   border-radius: 15px;
   width: 400px;
  `}
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background.grey};
`;

const Content = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
`;

const ShareIconsWrap = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background.grey};
`;

const Message = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

const CloseButton = styled.div`
  font-weight: bold;
  font-size: 38px;
  cursor: pointer;
  color: ${({ theme }) => darken('0.20', theme.colors.background.grey)};
`;

const CopyLinkWrap = styled.div`
  position: relative;
  margin-top: 20px;
`;

const Button = styled(PrimaryButton)`
  padding: 9px 12px;
  font-size: 16px;
  position: absolute;
  bottom: 8px;
  right: -7px;
  background-color: ${({ isUrlCopied }) => isUrlCopied && 'green'};
`;

const CopiedIcon = styled(FontAwesomeIcon)``;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tooltip from 'rc-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import flexbox from 'styles/utils/flexbox';
import { mediaQueries } from 'styles/utils/mediaQueries';
import truncate from 'styles/utils/truncate';
import IconCardPopover from './IconCardPopover';

const propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      icon: PropTypes.string.isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ).isRequired,
};

function IconCardsComponent({
  title,
  items,
  isHotelPage,
  isSearchResults,
  isCard,
  airlineWhitelabel,
  isFlexWrapActivated,
}) {
  const showText = isFlexWrapActivated ? !isFlexWrapActivated : isSearchResults || (isHotelPage && !isCard);

  const facilitiesList = isFlexWrapActivated && items.length > 3 ? items.slice(0, 2) : items;
  const hidenItems = items.length > 3 && isFlexWrapActivated && items.slice(2);

  return (
    <Root>
      {title && isHotelPage && !isCard && <Title>{title}</Title>}
      <List isHotelPage={isHotelPage} isCard={isCard} showText={showText}>
        {facilitiesList.map(({ id, icon, name }) => (
          <IconCard key={id} isHotelPage={isHotelPage} isCard={isCard} isFlexWrapActivated={isFlexWrapActivated}>
            {icon ? (
              <Tooltip placement='top' trigger={['hover']} overlay={name} destroyTooltipOnHide>
                <FontAwesomeIcon icon={icon} />
              </Tooltip>
            ) : null}
            {showText && (
              <IconCardText airlineWhitelabel={airlineWhitelabel} isNotCard={!isCard}>
                {name}
              </IconCardText>
            )}
          </IconCard>
        ))}
        {hidenItems && (
          <Tooltip
            placement='right'
            overlay={<IconCardPopover items={hidenItems} />}
            trigger={['hover']}
            destroyTooltipOnHide
          >
            <MoreFacilities>&#43; {hidenItems.length}</MoreFacilities>
          </Tooltip>
        )}
      </List>
    </Root>
  );
}

IconCardsComponent.propTypes = propTypes;

export default IconCardsComponent;

const Root = styled.div`
  user-select: none;
`;

const Title = styled.div`
  margin-bottom: 24px;
  font-family: ${({ theme }) => theme.fonts.displaySubtle};
  font-size: 2.8em;
  color: ${({ theme }) => theme.colors.text.templates.header};
`;

const List = styled.div`
  margin-right: ${({ isHotelPage, isCard }) => (isHotelPage && !isCard ? '20px' : 'auto')};
  ${flexbox({
    flexWrap: 'wrap',
    alignItems: 'center',
  })}
  gap: ${({ showText }) => (showText ? '12px' : '4px')};
`;

const IconCard = styled.div`
  ${flexbox({
    alignItems: 'center',
  })}
  ${truncate()}
  min-width: 25px;
  margin-right: ${({ isHotelPage, isCard }) => (isHotelPage && !isCard ? '8px' : '0')};
  width: ${({ isHotelPage, isCard }) => (isHotelPage && !isCard ? 'calc(100% * (1/2) - 15px)' : 'auto')};
  margin-bottom: ${({ isHotelPage, isCard }) => (isHotelPage && !isCard ? '16px' : 'auto')};
  border-radius: 8px;

  ${({ isFlexWrapActivated }) =>
    isFlexWrapActivated &&
    `
    min-width: 18px;
    width: 18px
    height: 22px;
  `}

  svg {
    max-width: 20px;
    margin: ${({ isHotelPage, isCard }) => (isHotelPage && !isCard ? '8px 6px' : '8px 8px 8px 0')};
    font-size: ${({ isFlexWrapActivated }) => (isFlexWrapActivated ? '13px' : '16px')};
    color: ${({ theme }) => (theme.colors.packageCard ? theme.colors.packageCard.icon : theme.colors.primary.dark)};
  }
`;

const IconCardText = styled.span`
  font-size: 1.3em;
  font-weight: ${({ airlineWhitelabel }) => (airlineWhitelabel && airlineWhitelabel.isAirBaltic ? 500 : 600)};
  color: ${({ theme }) => (theme.colors.packageCard ? theme.colors.packageCard.text : theme.colors.text.light)};
  text-align: center;
  white-space: nowrap;

  ${mediaQueries.fromAndBelow.xl`
    display: ${({ isNotCard }) => (isNotCard ? 'inline-block' : 'none')};
  `}
`;

const MoreFacilities = styled.div`
  position: relative;
  border-radius: 6px;
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);

  font-size: 10px;
`;

import React from 'react';
import { components } from 'react-select';

import { FormattedMessage } from 'i18n';
import { defaultOrigin } from '../constants';

const CustomSingleValue = ({ children, ...props }) => {
  if (props.data.value === defaultOrigin.value) {
    return (
      <components.SingleValue {...props}>
        <FormattedMessage
          id='adverts.component.option.allOrigins'
          description='All departure airports'
          defaultMessage='All departure airports'
        />
      </components.SingleValue>
    );
  }

  return <components.SingleValue {...props}>{children}</components.SingleValue>;
};

export default CustomSingleValue;

import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Margin } from 'styled-components-spacing';
import ReactPlaceholder from 'react-placeholder';

import { FormattedMessage } from 'i18n';
import PackageCardPlaceholder from 'components/packageCard/PackageCardPlaceholder';
import Option from './components/Option';
import SingleValue from './components/SingleValue';
import CarouselPackagesAdverts from './components/CarouselPackagesAdverts';

import flexbox from 'styles/utils/flexbox';
import { boxShadowValue } from 'styles/utils/boxShadow';
import { mediaQueries } from 'styles/utils/mediaQueries';
import chevron from 'static/icons/chevron.svg';

const propTypes = {
  packagesAdvertsCards: PropTypes.array,
  origins: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  selectedOrigin: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onSelectedOriginChange: PropTypes.func.isRequired,
  handleOnClickPrevPackage: PropTypes.func.isRequired,
  handleOnClickNextPackage: PropTypes.func.isRequired,
  assignCarouselRef: PropTypes.func.isRequired,
};

const AdvertsComponent = ({
  packagesAdvertsCards,
  hidePlaceholder,
  origins,
  selectedOrigin,
  onSelectedOriginChange,
  handleOnClickPrevPackage,
  handleOnClickNextPackage,
  assignCarouselRef,
  airlineWhitelabel,
  colors,
  advertTitleKey,
}) => (
  <Root>
    <Header>
      <FormattedMessage
        id='adverts.component.fromOrigin.label'
        description='From'
        defaultMessage='From'
        tagName={'span'}
      />
      <Select
        placeholder={'destinationsPlaceholder'}
        styles={getSelectStyles(colors)}
        className='select-origins-adverts'
        classNamePrefix='select-origins-adverts'
        options={origins}
        value={selectedOrigin}
        onChange={onSelectedOriginChange}
        components={{
          Option,
          SingleValue,
        }}
        isClearable={false}
        isSearchable={false}
      />
      <Title airlineWhitelabel={airlineWhitelabel}>
        <FormattedMessage
          id={advertTitleKey}
          description='Highlighted packages'
          defaultMessage='Highlighted packages'
        />
      </Title>
      <TitlePositioner />
      <Navigation>
        <Button onClick={handleOnClickPrevPackage} isPrev>
          <img src={chevron} alt='previous package' />
        </Button>
        <Margin right='0.5' inline={true} />
        <Button onClick={handleOnClickNextPackage} isNext>
          <img src={chevron} alt='next package' />
        </Button>
      </Navigation>
    </Header>
    <ReactPlaceholder
      ready={hidePlaceholder}
      showLoadingAnimation={true}
      customPlaceholder={<PackageCardPlaceholder />}
    >
      {hidePlaceholder ? (
        <CarouselPackagesAdverts items={packagesAdvertsCards} assignCarouselRef={assignCarouselRef} />
      ) : (
        <div></div>
      )}
    </ReactPlaceholder>
  </Root>
);

AdvertsComponent.propTypes = propTypes;

export default AdvertsComponent;

// Styled Components
const Root = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  padding: 24px 32px 32px;
  background-color: ${({ theme }) => theme.colors.primary.light};
  border-radius: 0;

  .select-origins-adverts {
    flex: 1 1 96px;
    margin-left: 8px;
  }

  ${mediaQueries.fromAndAbove.desktop`
    width: 992px;
    border-radius: 8px;
  `}
`;

const Header = styled.div`
  ${flexbox({
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  })}

  margin-bottom: 16px;

  span {
    color: ${({ theme }) => theme.colors.text.default};
    font-size: 16px;
    font-weight: 600;
  }
`;

const borderRadiusValue = '0.25rem';

const getSelectStyles = function (colors) {
  return {
    control: (styles) => ({
      ...styles,
      backgroundColor: colors.primary.default,
      border: 'transparent',
      borderRadius: '20px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: colors.primary.default,
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: colors.text.default,
      fontSize: '16px',
      fontWeight: '600',
      padding: '10px 8px',
    }),

    menu: (styles) => ({
      ...styles,
      top: 'calc(100% + 1px)',
      marginTop: '0',
      border: '1px solid',
      borderColor: colors.primary.default,
      borderRadius: 0,
      borderBottomLeftRadius: borderRadiusValue,
      borderBottomRightRadius: borderRadiusValue,
      boxShadow: boxShadowValue,
      fontSize: '1.6em',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isFocused ? colors.primary.default : colors.background.light,
      color: isSelected ? colors.text.default : 'inherit',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: '10px 8px',
    }),
  };
};

const Title = styled.h1`
  flex: 3 1 auto;
  text-align: center;
  color: ${({ airlineWhitelabel, theme }) =>
    airlineWhitelabel && airlineWhitelabel.isNorwegian
      ? theme.colors.text.templates.header
      : theme.colors.text.default};
  font-family: ${({ theme }) => theme.fonts.displaySubtle};
  font-weight: 400;
  font-size: 30px;

  ${mediaQueries.fromAndAbove.tablet`
    flex: 1 1 auto;
  `}

  ${mediaQueries.fromAndAbove.desktop`
    flex: 3 1 auto;
  `}

  ${mediaQueries.fromAndBelow.tablet`
    position: absolute;
    top: -60px;
    width: 100%;
    color: ${(props) => props.theme.colors.text.light};
  `}
`;

const TitlePositioner = styled.div`
  flex: 1 1 auto;

  ${mediaQueries.fromAndAbove.tablet`
    flex: 0 1 auto;
  `}

  ${mediaQueries.fromAndAbove.desktop`
    flex: 1 1 auto;
  `}

  ${mediaQueries.fromAndBelow.tablet`
    flex: 1 1 auto;
  `}
`;

const Navigation = styled.div`
  flex: 0 1 auto;
`;

const Button = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary.default};
  cursor: pointer;

  img {
    width: 7px;
  }

  ${({ isPrev }) =>
    isPrev &&
    `
    img {
      position: relative;
      left: -1px;
      transform: rotate(180deg);
    }
  `}

  ${({ isNext }) =>
    isNext &&
    `
    img {
      position: relative;
      left: 1px;
    }
  `}
`;

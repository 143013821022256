import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CarouselBaseContainer from 'components/carousel/CarouselBaseContainer';
import chevronWhite from 'static/icons/chevron-white.svg';

const CarouselHotelImages = ({ items }) => (
  <CarouselHotelImagesStyled>
    <CarouselBaseContainer
      items={items}
      nSettings={{
        className: 'carousel-hotel-images',
        nextArrow: <CustomArrow />,
        prevArrow: <CustomArrow />,
        infinite: false,
        lazyLoad: 'ondemand',
        onInit: function () {
          if (!process.browser) {
            return;
          }
          // Preload the 2nd image
          if (items[1] && items[1].props) {
            const imageUrl = items[1].props.src || items[1].props.image;
            if (imageUrl) {
              const img = document.createElement('img');
              img.src = imageUrl;
            }
          }
        },
        beforeChange: function (oldIndex, newIndex) {
          if (!process.browser) {
            return;
          }
          // When scrolling backwards, images are already loaded
          if (newIndex < oldIndex) {
            return;
          }

          const item = items[newIndex + 1];

          // You are at the last image, nothing else to load
          if (item === undefined) {
            return;
          }

          // Preload the next image
          const imageUrl = item.props.src || item.props.image;
          if (imageUrl) {
            const img = document.createElement('img');
            img.src = imageUrl;
          }
        },
      }}
    />
  </CarouselHotelImagesStyled>
);

CarouselHotelImages.propTypes = {
  items: PropTypes.array.isRequired,
};

export default CarouselHotelImages;

const CustomArrow = ({ className, style, onClick }) => (
  <div className={className} onClick={onClick} style={{ ...style }}>
    <img className={className} src={chevronWhite} alt='' />
  </div>
);

// Custom Styling
const CarouselHotelImagesStyled = styled.div`
  .slick-dots {
    bottom: 0;

    li {
      margin: 0;

      button {
        &:before {
          opacity: 0.65;
          color: white;
          font-size: 23px;
        }
      }

      &.slick-active {
        button {
          &:before {
            opacity: 1;
            color: white;
            font-size: 29px;
          }
        }
      }
    }
  }

  .slick-arrow {
    height: 100%;

    &:before {
      content: none;
    }

    &.slick-prev {
      left: 0;
      z-index: 1;

      img {
        transform: rotate(180deg) translate(0, 9px);
      }
    }

    &.slick-next {
      right: 0;
    }

    img {
      height: 16px;
      width: 30px;
    }
  }
`;

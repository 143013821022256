import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mediaQueries } from 'styles/utils/mediaQueries';
import ShareModal from './components/ShareModal';
import { useDispatch } from 'react-redux';
import executeTrackingEvent from 'managers/gtmEventTracking/executeTrackingEvent';
import { CLICKED_SHARE_BUTTON } from 'managers/gtmEventTracking/constants/eventsNames';
import PrimaryButton from 'components/buttons/primaryButton/PrimaryButton';
import { isMobile as getIsMobile } from 'utils/isMobile';

/**
/*
 * @param {function} handleOnClickShare - is showing Modal with available links for sharing favorite hotel
*/
function ShareIcon({ handleOnClickShare, className }) {
  return (
    <Content
      role='button'
      tabIndex={0}
      className={className}
      onClick={(e) => {
        handleOnClickShare();
        e.preventDefault();
      }}
    >
      <ShareIconWrap>
        <FontAwesomeIcon icon='share-alt' />
      </ShareIconWrap>
      <Message>
        <FormattedMessage id={'share.label'} description={'Share'} defaultMessage={'Share'} />
      </Message>
    </Content>
  );
}

/**
/*
 * @param {function} handleOnClickShare - is showing Modal with available links for sharing favorite hotel
*/
function ShareAll({ handleOnClickShare }) {
  return (
    <ButtonWrapper>
      <Button onClick={handleOnClickShare}>
        <FormattedMessage id='shareAll.label' description='Share All' defaultMessage='Share All' />
      </Button>
    </ButtonWrapper>
  );
}

/**
/*
 * @param {array} favoritedHotelsIds - array with ids of favorited hotels
 * @param {function} children - wrapper function with params handleOnClickShare - for whatever you want show, Share All button or Share icon
*/

export default function ShareHotels({ favoritedHotelsIds, packageLink, children, className }) {
  const [shareUrl, setShareUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUrlCopied, setIsUrlCopied] = useState(false);
  const isShareAll = !!favoritedHotelsIds;

  const dispatch = useDispatch();

  useEffect(() => {
    const url = typeof window !== 'undefined' && window.location;

    setShareUrl(
      isShareAll
        ? `${url.origin}${url.pathname}?shared=${favoritedHotelsIds}`
        : packageLink
        ? `${url.origin}${packageLink}`
        : url.href
    );
  }, [favoritedHotelsIds, isShareAll, packageLink]);

  const handleOnClickCloseModal = (event) => {
    event.stopPropagation();
    setIsModalOpen(false);
  };

  const handleOnClickShare = async () => {
    dispatch(executeTrackingEvent(CLICKED_SHARE_BUTTON));
    const isMobile = getIsMobile();
    if (navigator.share && isMobile) {
      try {
        const shareData = {
          url: shareUrl,
        };

        await navigator.share(shareData);
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      setIsModalOpen(true);
    }
  };

  const handleOnClickCopy = () => {
    navigator.clipboard.writeText(shareUrl);
    setIsUrlCopied(true);
  };

  const handleOnAfterClose = () => {
    setIsUrlCopied(false);
  };

  return (
    <Root className={className}>
      {children(handleOnClickShare)}
      {isModalOpen && (
        <ShareModal
          modalOpen={isModalOpen}
          onClickClose={handleOnClickCloseModal}
          shareUrl={shareUrl}
          onClickCopy={handleOnClickCopy}
          isUrlCopied={isUrlCopied}
          onAfterClose={handleOnAfterClose}
        />
      )}
    </Root>
  );
}

ShareHotels.ShareIcon = ShareIcon;
ShareHotels.ShareAll = ShareAll;

const Root = styled.div``;

const Content = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 12px;
  margin-left: 0.6rem;
  ${mediaQueries.fromAndBelow.desktop`
    margin-left:1rem;
  `};
`;

const ShareIconWrap = styled.div`
  font-size: 16px;
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.text.light};
`;
const Message = styled.div`
  align-self: center;
  ${mediaQueries.fromAndBelow.desktop`
    display:none;
  `};
`;

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

const Button = styled(PrimaryButton)({
  minWidth: '150px',
  fontSize: 14,
});

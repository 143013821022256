import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function IconCardPopover({ items }) {
  return (
    <Root>
      {items.map(({ id, icon, name }) => (
        <Item key={id}>
          <FontAwesomeIcon icon={icon} />
          {name}
        </Item>
      ))}
    </Root>
  );
}

const Root = styled('div')({
  padding: '2px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const Item = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
});
